<template>
  <tbody>
    <TableRow
      v-for="(item) of paginatedItems"
      :key="`${fieldName}:${item.idx}`"
      :index="item.idx"
      :item="item"
      :payload-fields="payloadFields"
      :empty-element="emptyElement"
      :read-only="readOnly"
      :readonly-rule="readonlyRule"
      :delete-rule="deleteRule"
      @openMarkedModal="$emit('openMarkedModal', $event)"
      @update:item="onItemUpdate($event, item.idx)"
      @delete="del"
    />
  </tbody>
  <v-pagination
    v-model="page"
    :length="pagesTotal"
    density="compact"
    color="primary"
  ></v-pagination>
</template>
<script>
import TableRow from '@/components/Tables/MainProducts/edit/components/TableBody/components/TableRow/index.vue';

import MainProductsTableEdit from '@/components/Tables/MainProducts/edit/index.vue';
import Section from '@/components/Section/edit/index.vue';
import BoxSizeSelect from '@/components/UiKit/Form/SelectboxBoxSize/index.vue';
import CarSelect from '@/components/UiKit/Form/SelectboxCar/index.vue';
import DriverSelect from '@/components/UiKit/Form/SelectboxDriver/index.vue';
import storagePicker from '@/components/UiKit/StoragePicker/index.vue';
import InputSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import IntegrationsModal from '@/components/Organizations/integrations/modal.vue';

export default {
  name: 'TableBody',
  components: {
    MainProductsTableEdit, Section, BoxSizeSelect, CarSelect, DriverSelect, storagePicker, InputSearch, IntegrationsModal, TableRow,
  },
  inject: ['storeModule', 'fields', 'update:modelValue', 'openMarkedModal'],
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    payloadFields: {
      type: Object,
      default: () => {},
    },
    fieldName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    payloadParent: {
      type: String,
      default: null,
    },
    innerIndex: {
      type: Number,
      default: null,
    },
    appendFields: {
      type: Array,
      default: () => [],
    },
    appendItems: {
      type: Array,
      default: () => [],
    },
    readonlyRule: {
      type: Function,
      default: () => false,
    },
    deleteRule: {
      type: Function,
      default: () => false,
    },
    label: {
      type: String,
      default: '',
    },
    emptyElement: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:items'],
  data() {
    return {
      page: 1,
      pageSize: 5,
      items: [],
    };
  },
  computed: {
    pagesTotal() {
      return Math.ceil(this.items.length / this.pageSize);
    },
    paginatedItems() {
      return this.items.slice(
        (this.page - 1) * this.pageSize,
        this.page * this.pageSize,
      );
    },
  },
  watch: {
    modelValue() {
      this.setData();
    },
  },
  beforeMount() {
    this.setData();
  },
  methods: {
    setData() {
      if (!this.modelValue) return;
      if (this.modelValue?.some((el) => el.source === 'excel')) {
        this.updateValue();
        return;
      }
      if (!this.modelValue?.some((el) => el.organization_product)) {
        this.initItems(false);
      } else {
        this.updateItems();
      }
    },
    initItems(update = true) {
      if (this.modelValue) {
        this.items = JSON.parse(JSON.stringify(this.modelValue)).map((row, i) => {
          Object.entries(row).forEach((el) => {
            if (this.fields[el[0]]?.field_type === 'default_table') {
              row[el[0]] = Array.isArray(el[1]) ? el[1] : el[1].values;
            } else if (this.fields[el[0]]?.is_collection && el[1]?.values && el[1]?.values?.length) {
              row[el[0]] = Array.isArray(el[1]) ? el[1].map((v) => v.id) : el[1].values.map((v) => v.id);
            } else if (el[1]?.value !== undefined) {
              row[el[0]] = el[1].value;
            }
            return el;
          });
          row.session_products.forEach((prod) => {
            if (!prod.boxes) return;
            const productBox = prod?.boxes?.find((el) => el.box === null);
            if (!productBox) prod.boxes.push({ count: 0, count_plan: 0, box: null });
          });
          row.idx = i;
          return row;
        });
      }
      if (update) this.updateValue();
    },
    updateItems() {
      const arr = [];
      this.modelValue.forEach((sessionProduct) => {
        const product = arr.find((el) => el.main_organization_product.id === sessionProduct.main_organization_product.id);
        if (!product) {
          arr.push({
            ...sessionProduct,
            main_organization_product_from: sessionProduct.main_organization_product_from,
            main_organization_product: sessionProduct.main_organization_product,
            organization_product_id: sessionProduct.main_organization_product.id,
            organization_product_from_id: sessionProduct.main_organization_product_from.id,
            session_products: [sessionProduct],
          });
        } else {
          product.session_products.push(sessionProduct);
        }
      });
      this.items = arr;
    },
    onItemUpdate(item, index) {
      this.items = this.items.map((el, i) => {
        if (i === index) {
          el = item;
        }
        return el;
      });
      this.updateValue();
    },
    updateValue() {
      const formattedItems = this.items.reduce((acc, item) => {
        item.organization_product_id = item.main_organization_product.id;
        if (item?.main_organization_product_from?.id) item.organization_product_from_id = item?.main_organization_product_from?.id;
        acc.push(item);
        return acc;
      }, []);
      setTimeout(() => {
        this.$emit('update:modelValue', formattedItems);
      });
    },
    // getOrganizationProduct(id) {
    //   return this.fields.organization_product_id.values.find((el) => el.id === id);
    // },
    // getOrganizationProductFrom(id) {
    //   if (!this.fields.organization_product_from_id) return {};
    //   return this.fields.organization_product_from_id.values.find((el) => el.id === id);
    // },
    updateInnerValue() {
      this.updateValue();
    },
    add() {
      this.items.push(structuredClone(this.emptyElement));
      this.updateValue();
      this.page = this.pagesTotal;
    },
    del(idx) {
      this.items = this.items
        .filter((el, i) => i !== idx);

      this.updateInnerValue();
    },
  },
};
</script>