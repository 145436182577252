<template>
  <div
    v-if="Object.keys(fields).length"
    class="mainProductsTable"
  >
    <div
      class="d-flex justify-space-between align-center pl-4 pt-4 pr-4"
      style="gap: 20px"
    >
      <Label class="pt-0">
        <template #default>
          {{ label }}
        </template>
        <template
          v-if="$slots['hint']"
          #hint
        >
          <slot name="hint"></slot>
        </template>
      </Label>
      <v-spacer></v-spacer>
      <ExcelBtn
        v-if="fields.excel_sample"
        class="ml-4"
        :disabled="loading"
        @click="openFileAside"
      >
      </ExcelBtn>
      <PdfBtn
        v-if="fields.pdf_sample"
        class="ml-4"
        :disabled="loading"
        @click="openFileAside('pdf')"
      ></PdfBtn>
      <slot name="buttons"></slot>
    </div>

    <v-table>
      <TableHead
        :fields="fields"
        :payload-fields="payloadFields"
      />
      <TableBody
        ref="tableBody"
        v-model="items"
        :fields="fields"
        :payload-fields="payloadFields"
        :empty-element="emptyElement"
        :read-only="readOnly"
        :readonly-rule="readonlyRule"
        :delete-rule="deleteRule"
        :loading="loading"
        @openMarkedModal="openPopup('markedProducts', {product: $event}, {width: 700})"
        @update:modelValue="update"
      />
    </v-table>

    <div class="d-flex justify-center">
      <v-btn
        v-if="!disabled && !readOnly"
        icon="mdi-plus"
        color="primary"
        size="x-small"
        class="my-4"
        @click="fields.require_planned_products && plannedProducts?.length ? openAddProductsModal() : add()"
      />
      <v-btn
        v-if="fields.require_boxes"
        size="x-small"
        color="info"
        append-icon="mdi-plus"
        class="ml-4 my-4"
        variant="flat"
        :disabled="fieldsLoading"
        @click="openBoxModal()"
      >
        ТАРы
      </v-btn>
    </div>
    <v-dialog
      v-model="isPopup"
      :width="popupWidth"
    >
      <MarkedProductsModal v-if="popupName === 'markedProducts'"></MarkedProductsModal>
      <AddProductsModal
        v-else-if="popupName === 'addProducts'"
        :payload="payload"
        :fields="fields"
        :planned-products="plannedProducts"
        @close="isPopup = false"
        @addProducts="onProductsAdd"
      />
      <BoxModal
        v-else-if="popupName === 'box'"
        :id="upperItem.id"
        :storage-id="boxModalStorageId"
        :payload="payload"
        :items="items.length ? items : payload['planned_main_products']"
        :current-boxes="currentBoxes"
        @close="isPopup = false"
        @delete-boxes="onBoxesDelete"
        @add-boxes="onBoxesAdd"
      />
    </v-dialog>
    <v-navigation-drawer
      v-model="isAside"
      location="right"
      temporary
      :width="400"
    >
      <slot name="asideContent">
        <ExcelLoad
          v-if="asideName === 'excel'"
          :sample="asideData.excel_sample ?? asideData.pdf_sample"
          :post-sample="asideData.excel_sample ?? asideData.pdf_sample"
          :aside-data="asideData"
          @setPayload="setPayloadFromExcel"
        />
      </slot>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { computed } from 'vue';
import TableHead from '@/components/Tables/MainProducts/edit/components/TableHead/index.vue';
import TableBody from '@/components/Tables/MainProducts/edit/components/TableBody/index.vue';

import StorageTableCreate from '@/components/Tables/StorageTable/create/StorageTable.create.vue';
import CarSelect from '@/components/UiKit/Form/SelectboxCar/index.vue';
import BoxSizeSelect from '@/components/UiKit/Form/SelectboxBoxSize/index.vue';
import DriverSelect from '@/components/UiKit/Form/SelectboxDriver/index.vue';
import storagePicker from '@/components/UiKit/StoragePicker/index.vue';
import InputSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import Section from '@/components/Section/edit/index.vue';
import IntegrationsModal from '@/components/Organizations/integrations/modal.vue';
import Label from '@/components/UiKit/Label/index.vue';
import MarkedProductsModal from '@/components/Modal/MarkedProductsModal/index.vue';
import ExcelLoad from '@/components/ExcelLoad/index.vue';
import AddProductsModal from '@/components/Tables/MainProducts/edit/components/AddProductsModal/index.vue';
import BoxModal from '@/components/Tables/MainProducts/edit/components/BoxModal/index.vue';
import ExcelBtn from '@/components/UiKit/ExcelBtn/index.vue';

import { popupMixin } from '@/mixins/popup';
import { asideMixin } from '@/mixins/aside';
import { payloadMixin } from '@/mixins/payload';
import PdfBtn from '@/components/UiKit/PdfBtn/index.vue';
import { getChapterFieldKey } from '@/utils/utils';

export default {
  name: 'MainProductsTableEdit',
  components: {
    PdfBtn,
    BoxModal,
    AddProductsModal,
    ExcelLoad,
    ExcelBtn,
    TableHead,
    TableBody,
    Label,
    storagePicker,
    BoxSizeSelect,
    StorageTableCreate,
    CarSelect,
    DriverSelect,
    InputSearch,
    Section,
    IntegrationsModal,
    MarkedProductsModal,
  },
  mixins: [popupMixin, asideMixin, payloadMixin],
  inject: ['storeModule', 'addExcelData', 'camelEntity'],
  provide() {
    return {
      fields: computed(() => this.fields),
      upperField: computed(() => this.upperField),
      pathToField: computed(() => this.pathToField),
      upperFields: computed(() => this.upperFields),
      fieldName: this.fieldName,
      canCreateSessionProduct: computed(() => this.canCreateSessionProduct),
      entityId: computed(() => this.upperItem.id),
      mainProductsLoading: computed(() => this.loading),
    };
  },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    rawData: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {},
    },
    fieldName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    payloadParent: {
      type: String,
      default: null,
    },
    innerIndex: {
      type: Number,
      default: null,
    },
    appendFields: {
      type: Array,
      default: () => [],
    },
    appendItems: {
      type: Array,
      default: () => [],
    },
    readonlyRule: {
      type: Function,
      default: () => false,
    },
    deleteRule: {
      type: Function,
      default: () => false,
    },
    label: {
      type: String,
      default: '',
    },
    upperItem: {
      type: Object,
      default: () => {},
    },
    upperField: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'setTableHeight'],
  data() {
    return {
      items: [],
      innerTablesItems: {},
      payloadFields: [],
      emptyElement: {
        id: 0, main_organization_product: { id: 0 }, session_products: [], main_organization_product_from: { id: 0 }, is_new: true,
      },
      excludedFields: ['types', 'label', 'field_type', 'count', 'count_plan', 'organization_product_from_id', 'organization_product_id', 'count_not_distributed'],
      asideDataFields: [],
      watchers: [],

      pathToField: [],
    };
  },
  computed: {
    canCreateSessionProduct() {
      return !!this.fields.can_create_session_product;
    },
    fieldsLoading() {
      return this.$store.getters[`${this.storeModule}/fieldsLoading`];
    },
    chapterValues() {
      return this.$store.getters['common/chapterValues'];
    },
    organizationId() {
      const key = getChapterFieldKey({ field: 'organizations', chapterField: 'organization_id' });
      return +this.chapterValues[key];
    },
    organization: {
      get() {
        return this.payload.organizations.find((el) => +el.organization_id === this.organizationId) || {};
      },
      set(newVal) {
        this.payload.organizations = this.payload.organizations.map((el) => {
          if (el.organization_id === this.organizationId) {
            el = newVal;
          }
          return el;
        });
      },
    },
    plannedProducts() {
      let obj = this.organization;
      const path = this.fields.planned_main_products_path.split('->');
      path.forEach((key) => {
        if (!key) return;
        const ff = this.upperFields[key];
        const chapterField = ff.chapter_fields[0];
        const chapterValuesKey = getChapterFieldKey({ field: key, chapterField });
        obj = obj[key].find((el) => `${el[chapterField]}` === this.chapterValues[chapterValuesKey]);
      });
      return obj?.planned_main_products || [];
    },
    boxModalStorageId() {
      return this.payload.storage_id;
    },
    currentBoxes() {
      const arr = [];
      this.items.forEach((item) => {
        item?.session_products?.forEach((pr) => {
          pr?.boxes?.forEach((box) => {
            if (box.box && !arr.find((el) => el.id === box.box.id)) arr.push(box.box);
          });
        });
      });
      return arr;
    },
    upperFields: {
      get() {
        return this.$store.getters['common/upperFields'];
      },
      set(val) {
        this.$store.commit('common/setUpperFields', val);
      },
    },
  },
  watch: {
    fields() {
      this.updateFields();
    },
    modelValue: {
      handler() {
        this.items = [...this.modelValue];
      },
      deep: true,
    },
  },
  beforeMount() {
    this.items = [...this.modelValue];
    this.updateFields();

    let f = this.upperField;
    const arr = [];
    let i = 0;
    while (f && f?.field_name !== 'organizations') {
      if (i >= 1) {
        arr.push(f.field_name);
      }
      i += 1;
      this.upperFields[f.field_name] = f;
      f = f.upperField;
    }
    this.pathToField = arr.toReversed();
  },
  methods: {
    updateFields() {
      this.appendFields.forEach((f) => {
        this.fields[f.field_name] = f;
      });
      this.payloadFields = {};
      Object.entries(this.fields).forEach((field) => {
        if (!this.excludedFields.includes(field[0])) {
          // eslint-disable-next-line prefer-destructuring
          if (!field?.[1]?.field_type) return;
          this.payloadFields[field[0]] = field[1];
          switch (field[1].field_type) {
          case 'int': {
            this.emptyElement[field[0]] = 0;
            break;
          }
          case 'string': {
            this.emptyElement[field[0]] = '';
            break;
          }
          case 'selectbox': {
            this.emptyElement[field[0]] = null;
            break;
          }
          case 'checkbox': {
            this.emptyElement[field[0]] = false;
            break;
          }
          case 'default_table': {
            this.emptyElement[field[0]] = [];
            break;
          }
          default: {
            this.emptyElement[field[0]] = null;
            break;
          }
          }
          if (field[1].emptyValue) {
            this.emptyElement[field[0]] = field[1].emptyValue;
          }
        }
      });
    },
    add() {
      this.$refs.tableBody.add();
    },
    onProductsAdd(products) {
      products.forEach((pr) => {
        pr.count = +pr.count;

        const payloadProduct = this.plannedProducts.find((el) => el.main_organization_product.id === pr.main_organization_product.id);
        const payloadSessionProduct = payloadProduct?.session_products.find((el) => el.organization_product.id === pr.organization_product.id);
        if (payloadSessionProduct) payloadSessionProduct.count_not_distributed -= pr.count;

        const prod = this.items.find((item) => item.main_organization_product.id === pr.main_organization_product.id); // находим товар первого уровня

        if (!prod) { // если товара нет, добавляем этот товар первого уровня с выбранным товаром второго уровня
          const newProduct = {
            main_organization_product: pr.main_organization_product,
            main_organization_product_from: pr.main_organization_product_from,
            organization_product_id: pr.organization_product.id,
            organization_product_from_id: pr.organization_product_from_id,
            count: 0,
            count_plan: +pr.count,
            session_products: [
              {
                ...pr,
                count: 0,
                count_plan: +pr.count,
                organization_product_id: pr.organization_product.id,
                boxes:
                    [{ count: 0, count_plan: pr.organization_product.id === pr.main_organization_product.id ? pr.count : 0, box: null }],
              },
            ],
          };
          this.items.push(newProduct);
        } else {
          this.items = this.items.map((item) => {
            if (item.main_organization_product.id === pr.main_organization_product.id) {
              item.count_plan += pr.count;
            }
            return item;
          });
          // eslint-disable-next-line no-unreachable
          const sessionProd = prod.session_products.find((item) => item.organization_product.id === pr.organization_product.id);
          if (!sessionProd) {
            pr.count_plan = pr.count;
            pr.count = 0;
            pr.boxes = [{ count: 0, count_plan: pr.count, box: null }];
            prod.session_products.push(pr);
            return;
          }
          if (!sessionProd?.boxes) return;
          const productBox = sessionProd?.boxes?.find((el) => el.box === null);
          if (productBox) productBox.count_plan = +productBox.count_plan + +pr.count;
          sessionProd.count_plan = +sessionProd.count_plan + +pr.count;
        }

        this.update(this.items);
      });
      this.isPopup = false;
    },
    onBoxesDelete(boxes) {
      boxes.forEach((box) => {
        if (!box) return;

        box.organization_products.forEach((product) => {
          this.items.forEach((item) => {
            const sessionProduct = item.session_products.find((el) => el.organization_product.id === product.id);
            if (sessionProduct) {
              sessionProduct.boxes = sessionProduct.boxes.filter((b) => b?.box?.id !== box.id);
              sessionProduct.count -= product.count;
              item.count -= product.count;
            }
          });
        });
      });
      this.update(this.items);
      this.isPopup = false;
    },
    onBoxesAdd(boxes) {
      boxes.forEach((box) => {
        if (!box) return;

        box.organization_products.forEach((product) => {
          const newBox = { count_plan: 0, count: product.count, box };
          this.items.forEach((item) => {
            const sessionProduct = item.session_products.find((el) => el.organization_product.id === product.id);

            if (sessionProduct && !sessionProduct.boxes.find((b) => b?.box?.id === box.id)) {
              sessionProduct.boxes.push(newBox);
            } else if (!sessionProduct) {
              item.session_products.push({
                organization_product: product, count: product.count, count_plan: 0, boxes: [newBox, { count_plan: 0, count: 0, box: null }],
              });
              item.count += product.count;
            }
          });
        });
      });
      this.updateValue();
      this.isPopup = false;
    },
    update($event) {
      this.items = $event;
      this.$emit('update:modelValue', $event);
    },
    openFileAside(type = 'excel') {
      const data = {};
      data.excel_sample = this.fields.excel_sample;
      data.pdf_sample = type === 'pdf' ? this.fields.pdf_sample : undefined;
      const keys = ['organization_id', 'organization_to_id', 'organization_from_id'];
      keys.forEach((key) => {
        const cvKey = Object.keys(this.chapterValues).find((k) => k.includes(key));
        if (this.fields[key] || this.chapterValues[cvKey]) {
          data[key] = this.fields[key] || this.chapterValues[cvKey];
        }
      });

      if (this.fields?.shipment_id) {
        data.shipment_id = this.fields.shipment_id;
      }
      if (this.fields?.marketplace_id) {
        data.marketplace_id = this.fields.marketplace_id;
      }

      if (this.payloadParent) {
        data.excel_payloadParent = this.payloadParent;
      }
      if (this.innerIndex !== undefined) {
        data.excel_payloadIndex = this.innerIndex;
      }
      this.openAside('excel', { ...data, ...this.addExcelData });
    },
    setPayloadFromExcel(data) {
      const items = data.main_products ?? data.planned_main_products ?? [];
      this.items = items;
      this.$nextTick(() => {
        this.closeAside();
        this.$emit('update:modelValue', this.items);
      });
    },
    openAddProductsModal() {
      this.isPopup = true;
      this.popupName = 'addProducts';
    },
    openBoxModal() {
      this.isPopup = true;
      this.popupName = 'box';
    },
  },
};
</script>

<style lang="sass" scoped>
.mainProductsTable
  border: 1px solid #eee
  border-radius: 5px
  margin-block: 10px
</style>