<template>
  <div
    v-if="field?.field_type"
    class="payloadField"
  >
    <v-text-field
      v-if="field.field_type === 'string' || field.field_type === 'text'"
      v-model="value[fieldName]"
      :label="field.label"
      min="0"
      density="compact"
      hide-details
      :disabled="disabled"
      @update:modelValue="update"
    />
    <v-text-field
      v-else-if="field.field_type === 'int' || field.field_type === 'number'"
      v-model="value[fieldName]"
      :label="field.label"
      min="0"
      density="compact"
      type="number"
      hide-details
      :disabled="disabled"
      @update:modelValue="update"
    />
    <InputSearch
      v-else-if="field.field_type === 'selectbox'"
      v-model="value[fieldName]"
      :label="field.label"
      :items="field.values"
      density="compact"
      :multiple="field.is_collection"
      :max-count="field?.max_count || 0"
      hide-details
      :disabled="disabled"
      @update:modelValue="update"
    />
    <CarSelect
      v-else-if="field.field_type === 'selectbox_driver_car'"
      v-model="value[fieldName]"
      :items="field.values"
      @update:modelValue="update"
    />
    <DriverSelect
      v-else-if="field.field_type === 'selectbox_driver'"
      v-model="value[fieldName]"
      :items="field.values"
      @update:modelValue="update"
    />
    <v-checkbox
      v-else-if="field.field_type === 'checkbox'"
      v-model="value[fieldName]"
      color="primary"
      hide-details
      :disabled="disabled"
      @update:modelValue="update"
    />

    <template v-else-if="field.field_type === 'box_size_select'">
      <BoxSizeSelect
        v-model="value[fieldName]"
        :items="field.values"
      />
    </template>

    <mainProductsTableEdit
      v-else-if="field.field_type === 'default_table'"
      v-model="value[fieldName]"
      :fields="field"
      :disabled="disabled && field.only_read !== false"
      :read-only="disabled"
      :payload-parent="fieldName"
      :field-name="fieldName"
      :inner-index="index"
      @update:modelValue="update"
    />

    <Section
      v-else-if="field.field_type === 'section'"
      v-model="value[fieldName]"
      :fields="field"
      :disabled="disabled && field.only_read !== false"
      :read-only="disabled"
      @update:modelValue="update"
    />

    <storagePicker
      v-else-if="field.field_type === 'selectbox_storage'"
      v-model="value[fieldName]"
      :prop-items="field.values"
      :prop-areas="field.areas"
      :storages="field.storages"
      density="compact"
      :multiple="false"
      :disabled="disabled"
      @update:modelValue="update"
    />

    <UiSelectboxProduct
      v-else-if="field.field_type === 'selectbox_organization_product'"
      v-model="value[fieldName]"
      :label="field.label"
      :items="field.values"
      density="compact"
      :multiple="field.is_collection"
      :max-count="field?.max_count || 0"
      hide-details
      :disabled="disabled"
      @update:modelValue="update"
    />

    <SelectboxCreate
      v-else-if="field.field_type === 'selectbox_create'"
      v-model="value[fieldName]"
      :field="field"
      :label="field.label"
      class="my-2"
      :items="field.values"
      density="compact"
      :multiple="field.is_collection"
      :max-count="field?.max_count || 0"
      hide-details
      :disabled="disabled"
      @update:modelValue="update"
    />

    <IntegrationsModal
      v-else-if="field.field_type === 'organization_integrations'"
      v-model="value[fieldName]"
      :field="field"
      :name="product['name']"
      :is-create="!product['id']"
      @update:modelValue="update"
    />

    <div v-else />
  </div>
</template>

<script>
import IntegrationsModal from '@/components/Organizations/integrations/modal.vue';
import CarSelect from '@/components/UiKit/Form/SelectboxCar/index.vue';
import BoxSizeSelect from '@/components/UiKit/Form/SelectboxBoxSize/index.vue';
import DriverSelect from '@/components/UiKit/Form/SelectboxDriver/index.vue';
import InputSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import MainProductsTableEdit from '@/components/Tables/MainProducts/edit/index.vue';
import Section from '@/components/Section/edit/index.vue';
import storagePicker from '@/components/UiKit/StoragePicker/index.vue';
import SelectboxCreate from '@/components/UiKit/Form/SelectboxCreate/index.vue';
import SelectboxProduct from '@/components/UiKit/Form/SelectboxProduct/index.vue';

export default {
  name: 'PayloadFields',
  components: {
    SelectboxProduct,
    SelectboxCreate,
    MainProductsTableEdit,
    Section,
    BoxSizeSelect,
    CarSelect,
    DriverSelect,
    storagePicker,
    InputSearch,
    IntegrationsModal,
  },
  props: {
    field: {
      type: Object,
      default: () => ({}),
    },
    product: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fieldName: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  emits: ['update:product'],
  data() {
    return {
      value: {},
    };
  },
  beforeMount() {
    this.value = { ...this.product };
  },
  methods: {
    update() {
      this.$emit('update:product', this.value);
    },
  },
};
</script>