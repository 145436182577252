<template>
  <div class="integrations">
    <v-card-title
      v-if="marketPlaces"
      class="title text-start"
    >
      Интеграции <span v-if="name">{{ name }}</span>
    </v-card-title>
    <div
      v-for="(mp, i) of marketPlaces"
      :key="i"
      class="mt-4"
    >
      <div class="integrations_list mb-4">
        {{ mp.name || 'Без названия' }}
        <v-btn
          v-if="!getAddedIntegration(mp.id)"
          size="small"
          color="primary"
          variant="flat"
          class="ml-4"
          :disabled="mp.code === 'ym' && isCreate"
          @click="openPopup('edit', {marketplace_id: mp.id, organizationApi: {...getAddedIntegration(mp.id), isAdded: !!getAddedIntegration(mp.id)}})"
        >
          Добавить
        </v-btn>
        <template
          v-else
        >
          <div class="actions">
            <v-btn
              v-if="getAddedIntegration(mp.id) && mp.code !== 'ym'"
              size="small"
              color="primary"
              variant="tonal"
              class="ml-4"
              style="width: 140px"
              @click="openPopup('edit', {marketplace_id: mp.id, organizationApi: {...getAddedIntegration(mp.id), isAdded: !!getAddedIntegration(mp.id)}})"
            >
              Изменить
            </v-btn>
            <v-btn
              v-else-if="getAddedIntegration(mp.id)"
              size="small"
              color="primary"
              variant="flat"
              class="ml-4"
              style="width: 140px"
              disabled
            >
              Добавлен
            </v-btn>
            <v-btn
              v-if="getAddedIntegration(mp.id)?.storages_fbs?.length"
              size="small"
              color="success"
              variant="tonal"
              class="ml-2"
              :disabled="!getAddedIntegration(mp.id)"
              @click="openWarehouseModal(mp)"
            >
              <v-icon>mdi-warehouse</v-icon>
            </v-btn>
            <v-btn
              size="small"
              color="error"
              variant="tonal"
              style="width: 50px"
              @click="openPopup('delete', {organizationApi: mp})"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </template>
      </div>
    </div>
    <v-dialog
      v-model="popup.meta.isOpen"
      width="600"
    >
      <EditModal
        v-if="popupName === 'edit'"
        :organization-api="popup.data.organizationApi"
        :fields="field"
        :marketplace-id="popup.data.marketplace_id"
        :mp-code="getMarketPlaceById(popup.data.marketplace_id)?.code"
        @close="onEditModalClose"
      />
      <ConfirmDelete
        v-else-if="popupName === 'delete'"
        @no="closePopup"
        @yes="onDelete"
      />
      <WarehouseModal
        v-else-if="popupName === 'warehouse'"
        :organization-api="popup.data.organizationApi"
        :raw-data="popup.data.organizationApi.rawData"
        :fields="field"
        :mp-code="getMarketPlaceById(popup.data.organizationApi.marketplace_id)?.code"
        @close="onWarehouseModalClose"
      />
    </v-dialog>
  </div>
</template>

<script>
import { popupMixin } from '@/mixins/popup';
import EditModal from './components/EditModal.vue';
import WarehouseModal
  from '@/components/Organizations/integrations/components/WarehouseModal.vue';
import ConfirmDelete from '@/components/Modal/ConfirmDelete/index.vue';
import { removeValuesWrapper } from '@/utils/utils';

export default {
  name: 'OrganizationIntegrations',
  components: { EditModal, ConfirmDelete, WarehouseModal },
  mixins: [popupMixin],
  inject: ['storeModule'],
  props: {
    modelValue: {
      type: Object,
      default: () => {},
    },
    field: {
      type: Object,
      default: () => {},
    },
    name: {
      type: String,
      default: '',
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      payload: {},
    };
  },
  computed: {
    marketPlaces() {
      return this.field?.marketplace_id?.values;
    },
    showDataRaw() {
      return this.$store.getters[`${this.storeModule}/showDataRaw`];
    },
  },
  watch: {
    modelValue() {
      this.init();
    },
  },
  beforeMount() {
    this.init();
  },
  methods: {
    init() {
      this.payload = this.modelValue;
      if (!this.payload) this.payload = [];
      this.payload = this.payload.map((el) => {
        el = removeValuesWrapper(el);

        if (el.storages_fbs) {
          el.storages_fbs.forEach((storage) => {
            removeValuesWrapper(storage);
          });
        }
        return el;
      });
    },
    getMarketPlaceById(id) {
      return this.field.marketplace_id.values.find((el) => el.id === id) || { code: null };
    },
    getAddedIntegration(id) {
      return this.payload.find((el) => el.marketplace_id === id && el.id >= 0) || null;
    },
    getRawIntegration(id) {
      return this.showDataRaw.organization_apis.values.find((el) => el.marketplace_id.value === id && el.id.value >= 0) || null;
    },
    onEditModalClose(value) {
      if (!value.isAdded) {
        this.payload.push(value);
      } else {
        this.payload = this.payload.map((el) => {
          if (el.id === value.id) {
            el = value;
          }
          return el;
        });
      }
      this.$emit('update:modelValue', this.payload);
      this.closePopup();
    },
    openWarehouseModal(mp) {
      this.openPopup('warehouse', {
        marketplace_id: mp.id,
        organizationApi: {
          ...this.getAddedIntegration(mp.id),
          isAdded: !!this.getAddedIntegration(mp.id),
          rawData: this.getRawIntegration(mp.id),
        },
      });
    },
    onWarehouseModalClose(val) {
      this.payload = this.payload.map((el) => {
        if (el.marketplace_id === val.marketplace_id) {
          el = val;
        }
        return el;
      });
      this.$emit('update:modelValue', this.payload);
      this.closePopup();
    },
    onDelete() {
      this.payload = this.payload
        .map((el) => {
          if (el.marketplace_id === this.popup.data.organizationApi.id) {
            if (el.id > 0) el.id = -el.id;
            else if (el.id === 0) el.id = 'del';
          }
          return el;
        })
        .filter((el) => el.id !== 'del');
      this.$emit('update:modelValue', this.payload);
      this.closePopup();
    },
  },
};
</script>

<style lang="sass" scoped>
.integrations
  &_list
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 10px
    max-width: 600px
</style>