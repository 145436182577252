<template>
  <div
    v-if="Object.keys(fields).length"
    class="defaultTable"
  >
    <div class="d-flex justify-space-between align-center pt-4 pr-4">
      <Label
        class="pt-0 pl-4"
      >
        <template #default>
          {{ label }}
        </template>
        <template
          v-if="$slots['hint']"
          #hint
        >
          <slot name="hint"></slot>
        </template>
      </Label>
      <ExcelBtn
        v-if="fields.excel_sample && !readOnly"
        class="ml-4"
        :disabled="loading"
        @click="openFileAside('excel')"
      />
      <PdfBtn
        v-if="fields.pdf_sample && !readOnly"
        class="ml-4"
        @click="openFileAside('pdf')"
      />
    </div>
    <div class="defaultTable_chapters__wrapper">
      <Chapters
        v-if="is_chapter"
        ref="chapters"
        :prop-chapter="chapter"
        :chapters="visibleChapters"
        class="px-4"
        :fields="fields"
        :label="label"
        :editable="!fields?.only_read && !readOnly"
        :max-count="fields?.max_count || null"
        :chapter-fields="chapterFields"
        @chapter-select="onChapterSelect"
        @chapter-delete="onChapterDelete"
        @chapter-edit="openCreateChapterModal"
        @chapters-clear="onChaptersClear"
        @add="openCreateChapterModal"
      ></Chapters>
    </div>
    <v-table>
      <DefaultTableHead
        v-if="!is_chapter"
        :payload-fields="payloadFields"
      />
      <DefaultTableBody
        v-if="!is_chapter"
        v-bind="slots"
        :disabled="disabled"
        :field-name="fieldName"
        :payload-fields="payloadFields"
        :read-only="readOnly"
        :delete-rule="deleteRule"
        :readonly-rule="readonlyRule"
        :displayed-items="displayedItems"
        :displayed-raw-data="displayedRawData"
        :loading="loading"
        :fields="{...fields, upperField, field_name: fieldName}"
        @update:chapter="onInnerChapterUpdate"
        @update="updateInnerValue"
        @update:raw="updateRawItemField"
        @delete="del"
        @set-watch-query="setWatchQuery"
        @setAllValues="setAllValues"
      >
        <template
          v-for="(field) of Object.entries(payloadFields)"
          v-slot:[`col.${field[0]}`]="{item, idx, update}"
        >
          <slot
            :name="`col.${field[0]}`"
            :field="field"
            :item="item"
            :idx="idx"
            :update="update"
          ></slot>
        </template>
      </DefaultTableBody>
      <DefaultTableChaptersBody
        v-else
        v-bind="slots"
        :disabled="disabled"
        :field-name="fieldName"
        :payload-fields="payloadFields"
        :read-only="readOnly"
        :delete-rule="deleteRule"
        :readonly-rule="readonlyRule"
        :displayed-items="displayedItems"
        :displayed-raw-data="displayedRawData"
        :loading="loading"
        :chapter-fields="chapterFields"
        :chapters-loaded="chaptersLoaded"
        :fields="{...fields, upperField, field_name: fieldName}"
        @update:chapter="onInnerChapterUpdate"
        @update="updateInnerValue"
        @update:raw="updateRawItemField"
        @delete="delChapter"
        @set-watch-query="setWatchQuery"
      >
      </DefaultTableChaptersBody>
    </v-table>
    <div
      class="d-flex justify-center"
      style="gap: 10px"
    >
      <v-btn
        v-if="!disabled && !readOnly && !is_chapter"
        icon="mdi-plus"
        color="primary"
        size="x-small"
        class="my-4"
        @click="add"
      />
      <AddMultipleButton
        v-if="!disabled && !readOnly && !is_chapter && canAddMultiple"
        @add="addMultiple"
      >
      </AddMultipleButton>
    </div>
    <v-dialog
      v-model="isPopup"
      width="700"
    >
      <CreateChapter
        v-if="popupName === 'createChapter'"
        :fields="fields"
        :field-name="popupData.chapterField"
        :chapter-values="popupData.chapterValues"
        :old-chapter-name="popupData.chapterName"
        :show-data-raw="rawData"
        :chapter-fields="chapterFields"
        :label="label"
        @add="onChapterAdd"
        @edit="onChapterEdit"
      />
    </v-dialog>
    <template v-if="isAside">
      <v-navigation-drawer
        v-model="isAside"
        location="right"
        temporary
        :width="400"
      >
        <slot name="asideContent">
          <ExcelLoad
            v-if="asideName === 'excel'"
            :sample="asideData.excel_sample ?? asideData.pdf_sample"
            :post-sample="asideData.excel_sample ?? asideData.pdf_sample"
            :aside-data="asideData"
            :type="asideData.excel_sample ? 'excel' : 'pdf'"
            @setPayload="setPayloadFromExcel"
          />
        </slot>
      </v-navigation-drawer>
    </template>
  </div>
</template>
<script>
import AddMultipleButton from '@/components/Tables/DefaultTable/components/edit/components/AddMultipleButton.vue';
import ExcelLoad from '@/components/ExcelLoad/index.vue';
import Label from '@/components/UiKit/Label/index.vue';
import Chapters from '@/components/Chapters/index.vue';
import CreateChapter from '@/components/Tables/DefaultTable/components/edit/components/CreateChapter/index.vue';
import DefaultTableHead from '@/components/Tables/DefaultTable/components/edit/components/Head/index.vue';
import DefaultTableBody from './components/Body/components/DefaultBody/index.vue';
import DefaultTableChaptersBody
  from './components/Body/components/ChaptersBody/index.vue';
import { popupMixin } from '@/mixins/popup';
import { asideMixin } from '@/mixins/aside';
import { getChapterFieldKey, removeValuesWrapper, transformObject } from '@/utils/utils';
import chaptersMixin from '@/components/Tables/DefaultTable/mixins/chapters';
import ExcelBtn from '@/components/UiKit/ExcelBtn/index.vue';
import PdfBtn from '@/components/UiKit/PdfBtn/index.vue';

export default {
  name: 'DefaultTableEdit',
  components: {
    AddMultipleButton,
    ExcelLoad,
    ExcelBtn,
    PdfBtn,
    Label,
    Chapters,
    CreateChapter,
    DefaultTableHead,
    DefaultTableBody,
    DefaultTableChaptersBody,
  },
  mixins: [popupMixin, asideMixin, chaptersMixin],
  inject: ['storeModule', 'addExcelData', 'canSelectAll'],
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    rawData: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {},
    },
    fieldName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    payloadParent: {
      type: String,
      default: null,
    },
    innerIndex: {
      type: Number,
      default: null,
    },
    appendFields: {
      type: Array,
      default: () => [],
    },
    appendItems: {
      type: Array,
      default: () => [],
    },
    readonlyRule: {
      type: Function,
      default: () => false,
    },
    deleteRule: {
      type: Function,
      default: () => false,
    },
    label: {
      type: String,
      default: '',
    },
    is_chapter: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    slots: {
      type: Object,
      default: () => ({}),
    },
    upperField: {
      type: Object,
      default: null,
    },
    emptyElementAdd: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update:modelValue', 'update:chapter', 'update:raw', 'setWatchQuery'],
  data() {
    return {
      items: [],
      innerTablesItems: {},
      payloadFields: [],
      emptyElement: { id: 0 },
      excludedFields: ['types', 'label', 'field_type', 'chapter_fields', 'priority'],
      watchers: [],
      loadedChapters: [],
      deletedChapters: [],
      chapter: null,
      chaptersLoaded: false,
    };
  },
  computed: {
    allItems() {
      return this.items;
    },
    displayedItems: {
      get() {
        if (!this.chapterFields) return this.allItems;
        return this.allItems.filter((el) => {
          const chapterValues = this.getChapterValuesFromName(this.chapter);
          return this.isEqualToChapterValues(chapterValues, el) && !el.is_delete;
        });
      },
      set(val) {
        let i = 0;
        this.allItems = this.allItems.map((el) => {
          const chapterValues = this.getChapterValuesFromName(this.chapter);
          if (this.isEqualToChapterValues(chapterValues, el)) {
            el = val[i];
            i++;
          }
          return el;
        });
      },
    },
    displayedRawData: {
      get() {
        const chapterVals = this.getChapterValuesFromName(this.chapter);
        return Array.isArray(this.rawData) ? [...this.rawData]?.filter((el) => this.isEqualToChapterValues(chapterVals, removeValuesWrapper({ ...el }))) : [];
      },
      // set(val) {
      //   const chapterVals = this.getChapterValuesFromName(this.chapter);
      //   const arr = [
      //     ...this.rawData
      //       .filter((el) => !this.isEqualToChapterValues(chapterVals, removeValuesWrapper({ ...el }))),
      //     ...val,
      //   ];
      //   this.$emit('update:raw', arr);
      // },
    },
    chapterValues() {
      return this.$store.getters['common/chapterValues'];
    },
    canAddMultiple() {
      return !!this.fields?.multiple_addition;
    },
  },
  watch: {
    rawData: {
      handler() {
        this.updateItems();
      },
      deep: true,
      flush: 'post',
    },
    modelValue: {
      handler() {
        this.updateItems();
      },
      deep: true,
      flush: 'post',
    },
    fields: {
      handler() {
        this.updateFields();
      },
      flush: 'post',
    },
    'visibleChapters.length': {
      handler(newVal, oldVal) {
        if (oldVal === 0 && newVal) {
          const ctx = this;
          this.$nextTick(() => {
            if (ctx.$refs?.chapters) ctx.$refs?.chapters?.onUpdate(ctx.visibleChapters[0]);
          });
          // this.onChapterSelect(this.visibleChapters[0]);
        }
      },
    },
    isPopup(newVal) {
      if (!newVal) {
        this.closePopup();
      }
    },
  },
  async beforeMount() {
    this.initItems();
    this.updateFields();
    Object.entries(this.chapterFields).forEach(([key]) => {
      const k = getChapterFieldKey({ field: this.fieldName, chapterField: key });
      this.$watch(() => this.chapterValues[k], async function (newVal) {
        const query = { ...this.$route.query };
        query[key] = newVal;
        setTimeout(() => {
          this.$router.replace({ query });
        });
      });
    });
  },
  methods: {
    add() {
      this.items.push(structuredClone({ ...this.emptyElement, _index: this.items.length }));
      this.$emit('update:modelValue', this.items);
    },
    addMultiple(n) {
      for (let i = 0; i < n; i++) {
        this.items.push(structuredClone({ ...this.emptyElement, _index: this.items.length + n }));
      }
      this.$emit('update:modelValue', this.items);
    },
    del(idx) {
      this.items = this.items
        .map((el, i) => {
          if ((i === idx)) {
            if (el.id)el.id = -el.id;
            else el.id = 'del';
          }
          return el;
        })
        .filter((el) => el.id !== 'del');
      this.updateInnerValue();
    },
    initItems() {
      if (this.modelValue) {
        this.items = JSON.parse(JSON.stringify(this.modelValue)).map((row, i) => {
          Object.entries(row).forEach((el) => {
            if (this.fields[el[0]]?.field_type === 'default_table') {
              row[el[0]] = Array.isArray(el[1]) ? el[1] : el[1].values;
            } else if (this.fields[el[0]]?.is_collection && el[1].values && el[1].values.length) {
              row[el[0]] = Array.isArray(el[1]) ? el[1].map((v) => v.id) : el[1].values.map((v) => v.id);
            } else if (el[1]?.value !== undefined) {
              row[el[0]] = el[1].value;
            }
            return el;
          });
          // eslint-disable-next-line no-underscore-dangle
          row._index = i;
          return row;
        });
      }

      this.updateInnerValue();
    },
    updateItems() {
      this.items = JSON.parse(JSON.stringify(this.modelValue)).map((row) => {
        Object.entries(row).forEach((el) => {
          if (el[1]?.value !== undefined) {
            row[el[0]] = el[1].value;
          } else if (this.fields[el[0]]?.is_collection && el[1]?.values && el[1]?.values.length) {
            row[el[0]] = el[1].values.map((v) => v.id);
          }
          return el;
        });
        return row;
      });
    },
    setAllValues({ field, value }) {
      this.items = this.items.map((el) => {
        el[field] = value;
        return el;
      });
      this.updateInnerValue();
    },
    updateFields() {
      this.appendFields.forEach((f) => {
        this.fields[f.field_name] = f;
      });
      this.payloadFields = {};
      Object.entries(this.fields).forEach((field) => {
        if (!this.excludedFields.includes(field[0])) {
          // eslint-disable-next-line prefer-destructuring
          if (!field[1].field_type) return;
          if (this.initialChapterFields && this.initialChapterFields.includes(field[0])) return;
          if (field[1].request_on_change) {
            this.setWatchQuery({ field: field[0], value: this.displayedItems?.[0]?.[field[0]] || false });
          }
          this.payloadFields[field[0]] = field[1];
          switch (field[1].field_type) {
          case 'int': {
            this.emptyElement[field[0]] = 0;
            break;
          }
          case 'string': {
            this.emptyElement[field[0]] = '';
            break;
          }
          case 'selectbox': {
            this.emptyElement[field[0]] = field[1]?.default_id ?? null;
            break;
          }
          case 'checkbox': {
            this.emptyElement[field[0]] = false;
            break;
          }
          case 'default_table': {
            this.emptyElement[field[0]] = [];
            break;
          }
          case 'default_table_chapters': {
            this.emptyElement[field[0]] = [];
            break;
          }
          case 'main_products': {
            this.emptyElement[field[0]] = [];
            break;
          }
          case 'child_main_products': {
            this.emptyElement[field[0]] = [];
            break;
          }
          default: {
            this.emptyElement[field[0]] = null;
            break;
          }
          }
          if (field[1].emptyValue) {
            this.emptyElement[field[0]] = field[1].emptyValue;
          }
        }
      });
      // if (this.canSelectAll) {
      //   this.payloadFields.canSelect = {
      //     field_type: 'checkbox',
      //     label: 'Выделение ',
      //     only_read: false,
      //   };
      // }

      const values = this.getChapterValuesFromName(this.chapter);
      this.emptyElement = { ...this.emptyElement, ...values, ...this.emptyElementAdd };

      // this.emptyElement[this.chapterField] = this.chapter;
    },
    updateInnerValue() {
      this.$emit('update:modelValue', this.items);
    },
    updateRawItemField(idx, field, val) {
      if (!val) return;
      const cloneVal = JSON.parse(JSON.stringify(val));
      let arr = [];
      if (val) {
        arr = this.displayedRawData.map((el, i) => {
          if (i === idx) {
            if (Array.isArray(val)) {
              if (el[field]) el[field].values = cloneVal;
              else el[field] = { values: cloneVal };
            } else if (el[field]) el[field].value = cloneVal;
            else el[field] = { value: cloneVal };
          }
          return el;
        });
      }
      this.$emit('update:raw', [...arr, ...this.rawData.filter((el) => !arr.find((a) => a.id === el.id))]);
    },
    getChapterName(values) {
      return Object.entries(values).reduce((acc, el) => {
        acc += `${el[0]}=${el[1]}&`;
        return acc;
      }, '');
    },
    async onChapterSelect(chapterName) {
      this.chapter = chapterName;
      const values = this.getChapterValuesFromName(this.chapter);
      this.emptyElement = { ...this.emptyElement, ...values };
      const query = { ...this.chapterValues };

      const requestValues = {};
      Object.entries(this.fields).forEach(([fieldName, value]) => {
        if (value.request_on_change) {
          this.setWatchQuery({ field: fieldName, value: this.displayedItems?.[0]?.[fieldName] || false });
        }
      });

      Object.entries(values).forEach(([fieldName, value]) => {
        query[fieldName] = value;
        const cvKey = getChapterFieldKey({ field: this.fieldName, chapterField: fieldName });
        this.chapterValues[cvKey] = `${value}`;
        if (this.fields.request_on_change || this.chapterFields[fieldName].request_on_change) {
          requestValues[fieldName] = value;
        }
      });
      this.$store.commit('common/setChapterValues', this.chapterValues);

      if (Object.entries(requestValues).length) {
        this.$emit('update:chapter', { values: requestValues });
      }
    },
    onInnerChapterUpdate($event) {
      this.$emit('update:chapter', $event);
    },
    onChaptersClear() {
      this.chapters.forEach((ch) => {
        this.onChapterDelete(ch);
      });
    },
    onChapterDelete(chapterName) {
      const values = this.getChapterValuesFromName(chapterName);
      this.items = this.items
        .map((item) => {
          if (this.isEqualToChapterValues(values, item)) {
            item.is_delete = true;
          }
          return item;
        })
      // удаленяем не сохраненные chapter
        .filter((item) => !(this.isEqualToChapterValues(values, item) && item.id === 0));
      this.deletedChapters.push(chapterName);
      if (this.chapters.length) {
        this.onChapterSelect(this.chapters[0]);
      }
      this.updateInnerValue();
    },
    delChapter(idx) {
      const chapterVals = this.getChapterValuesFromName(this.chapter);
      const chapterFieldItems = this.items.filter((el) => this.isEqualToChapterValues(chapterVals, el));
      if (chapterFieldItems.length === 1) {
        this.onChapterDelete(this.chapter);
      } else {
        this.del(idx);
      }
    },
    openCreateChapterModal(chapterName) {
      const chapterValues = chapterName ? this.getChapterValuesFromName(chapterName) : undefined;
      this.openPopup('createChapter', { chapterValues, chapterName });
    },
    async onChapterAdd(chapterValues) {
      const name = this.getChapterName(chapterValues);
      this.emptyElement = { ...this.emptyElement, ...chapterValues };
      this.deletedChapters = this.deletedChapters.filter((ch) => ch !== name);
      await this.onChapterSelect(name);
      if (!this.visibleChapters.some((chapterName) => chapterName === name)) {
        this.add();
      }
      this.closePopup();
    },
    async onChapterEdit(chapterValues, oldName) {
      const name = this.getChapterName(chapterValues);
      const oldValues = this.getChapterValuesFromName(oldName);
      this.deletedChapters = this.deletedChapters.filter((ch) => ch !== name);

      this.items = this.items.map((el) => {
        if (this.isEqualToChapterValues(oldValues, el)) {
          el = { ...el, ...chapterValues };
        }
        return el;
      });
      this.updateInnerValue();
      await this.onChapterSelect(name);

      this.closePopup();
    },
    isEqualToChapterValues(chapterValues, value) {
      let isEqual = true;
      Object.entries(chapterValues).forEach(([key, val]) => {
        if (String(value[key]) !== String(val)) {
          isEqual = false;
        }
      });
      return isEqual;
    },
    getChapterValuesFromName(chapterName) {
      if (!chapterName) return {};
      return chapterName.split('&').reduce((acc, field) => {
        const [key, value] = field.split('=');
        if (key)acc[key] = value;
        return acc;
      }, {});
    },
    getChapterValues(chapterFields, item) {
      const obj = {};
      Object.entries(chapterFields).forEach(([key]) => {
        obj[key] = item[key];
      });
      return obj;
    },
    setWatchQuery({ field, value }) {
      this.$emit('setWatchQuery', { field, value });
    },
    openFileAside(type = 'excel') {
      const data = {};
      data.excel_sample = type === 'excel' ? this.fields.excel_sample : undefined;
      data.pdf_sample = type === 'pdf' ? this.fields.pdf_sample : undefined;
      const keys = ['organization_id', 'organization_to_id', 'organization_from_id'];
      keys.forEach((key) => {
        const cvKey = Object.keys(this.chapterValues).find((k) => k.includes(key));
        if (this.fields[key] || this.chapterValues[cvKey]) {
          data[key] = this.fields[key] || this.chapterValues[cvKey];
        }
      });
      if (this.fields?.shipment_id) {
        data.shipment_id = this.fields.shipment_id;
      }
      if (this.fields?.marketplace_id) {
        data.marketplace_id = this.fields.marketplace_id;
      }
      this.openAside('excel', { ...data, ...this.addExcelData });
    },
    setPayloadFromExcel(data) {
      if (!data[this.fieldName]) return;
      this.items = data[this.fieldName].map((el) => transformObject(el));
      this.updateInnerValue();
      this.$nextTick(() => {
        this.updateFields();
      });
      if (!this.chapter) {
        this.onChapterSelect(this.chapters[0]?.id);
      }
    },
    log(...args) {
      console.log(...args);
    },
  },
};
</script>

<style lang="sass" scoped>
.defaultTable
  display: grid
  border: 1px solid #eee
  border-radius: 5px
  margin-block: 10px

.defaultTable_chapters__wrapper
  max-width: 100%
  overflow: auto

.row-chapter
  padding-inline: 0 !important
  & > td:first-child
    padding-inline: 0 !important
  & > :first-child
    border-radius: 0
    margin-block: 0
</style>