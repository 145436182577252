<template>
  <SelectboxSearch
    v-model="value"
    :multiple="multiple"
    :disabled="disabled"
    :items="items"
    :label="label"
    :variant="variant"
    :clearable="clearable"
    @update:modelValue="onUpdate"
  >
    <template
      v-if="selectedItem?.photo_url"
      #prepend
    >
      <ProductPhoto :url="selectedItem?.photo_url"></ProductPhoto>
    </template>
    <template #item="{item, props}">
      <v-list-item v-bind="props">
        <template #prepend="{isSelected, select}">
          <div class="d-flex">
            <v-checkbox
              v-if="multiple"
              :model-value="isSelected"
              hide-details
              density="compact"
              @click.prevent="select(!isSelected)"
            />
            <img
              :src="item.raw?.photo_url || '/img/emptyImage.jpg'"
              class="productImage mr-2"
              alt=""
            >
          </div>
        </template>
        <template #append>
          <p v-if="item.raw?.count_in_box">
            <v-icon
              color="primary"
            >
              mdi-package-variant
            </v-icon>
            ({{ item.raw?.count_in_box }})
          </p>
        </template>
        <template #subtitle>
          {{ item.raw.type_rus }}
        </template>
      </v-list-item>
    </template>
    <template
      v-if="$slots['append-item']"
      #append-item
    >
      <slot name="append-item"></slot>
    </template>
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner">
      </slot>
    </template>

    <template
      v-if="$slots['append-inner']"
      #append-inner
    >
      <slot name="append-inner">
      </slot>
    </template>
  </SelectboxSearch>
</template>

<script>
import ProductPhoto from '@/components/ProductPhoto/index.vue';
import SelectboxSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';

export default {
  name: 'SelectBoxProduct',
  components: { ProductPhoto, SelectboxSearch },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: 'filled',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    showOrganization: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  computed: {
    selectedItem() {
      return this.items.find((el) => el.id === this.value);
    },
  },
  watch: {
    modelValue() {
      if (this.multiple && this.modelValue) {
        this.value = [...this.modelValue];
      } else {
        this.value = this.modelValue;
      }
    },
  },
  beforeMount() {
    if (this.modelValue !== undefined && this.modelValue !== null) {
      this.value = this.modelValue;
    } else if (this.multiple) {
      this.value = [];
    } else {
      this.value = '';
    }
  },
  methods: {
    onUpdate() {
      this.$emit('update:modelValue', this.value);
    },
  },
};
</script>

<style lang="sass" scoped>
.productImage
  width: 30px
  height: 50px
  object-fit: cover
  border-radius: 5px
</style>