<template>
  <div
    class="chapters"
  >
    <v-tabs
      :model-value="chapter"
      color="primary"
      class="align-center"
      @update:model-value="chapter = $event"
    >
      <v-tab
        v-for="(ch, i) of (chaptersWithLabels)"
        :key="i"
        :value="ch.id"
        @click="onUpdate(ch.id)"
      >
        <slot
          name="chapter"
          :value="i"
        >
          {{ ch.name }}
          <ActionsMenu
            v-if="editable"
            :actions="{ copy: false, edit: fields.can_change_chapters, delete: true }"
            @edit="onEdit(ch.id)"
            @delete="onDelete(ch.id)"
          ></ActionsMenu>
        </slot>
      </v-tab>
    </v-tabs>
    <template v-if="canAddNew">
      <v-btn
        color="primary"
        size="x-small"
        append-icon="mdi-plus"
        @click="$emit('add')"
      >
        Добавить
      </v-btn>
    </template>
    <template v-if="editable && chaptersWithLabels.length > 1">
      <v-btn
        color="error"
        variant="tonal"
        size="x-small"
        @click="onDeleteAll"
      >
        Удалить все
      </v-btn>
    </template>
  </div>
</template>

<script>
import ActionsMenu from '@/components/ActionsMenu/ActionsMenu.vue';

export default {
  name: 'EntityChapters',
  components: { ActionsMenu },
  props: {
    chapters: {
      type: Array,
      default: () => ([]),
    },
    propChapter: {
      type: String,
      default: null,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    editable: {
      type: Boolean,
      default: true,
    },
    maxCount: {
      type: Number,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    chapterFields: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['chapterSelect', 'add', 'chapterDelete', 'chaptersClear', 'chapterEdit'],
  data() {
    return {
      chapter: 'main',
    };
  },
  computed: {
    canAddNew() {
      if (this.maxCount === null || this.chapters.length < this.maxCount) return this.editable;
      return false;
    },
    chaptersWithLabels() {
      return this.chapters.map((chapterName) => {
        const values = this.getChapterValuesFromName(chapterName);
        if (!Object.entries(values).length) return { id: null, name: '' };
        const key = Object.entries(values)[0][0];
        const rawValue = Object.entries(values)[0][1];
        let append = '';
        if (values.is_all_box && (values.is_all_box !== 'false' && values.is_all_box !== 'null')) {
          append = ' (Вся ТАРа)';
        }
        const name = this.chapterFields[key]?.values?.length ? this.chapterFields[key].values.find((el) => String(el.id) === String(rawValue))?.name : rawValue;
        return { id: chapterName, name: name + append };
      });
    },
  },
  watch: {
    propChapter(val) {
      this.chapter = val;
    },
  },
  beforeMount() {
    if (this.propChapter) {
      this.chapter = this.propChapter;
    } else if (this.chapters.length) {
      this.chapter = this.chapters[0];
      this.$emit('chapterSelect', this.chapters[0]);
    }
  },
  methods: {
    onUpdate(val) {
      this.chapter = val;
      this.$emit('chapterSelect', this.chapter);
    },
    onEdit(val) {
      this.$emit('chapterEdit', val);
    },
    onDelete(val) {
      this.$emit('chapterDelete', val);
    },
    onDeleteAll() {
      this.$emit('chaptersClear');
    },
    getChapterValuesFromName(chapterName) {
      if (!chapterName || typeof chapterName !== 'string') return {};
      return chapterName.split('&').reduce((acc, field) => {
        const [key, value] = field.split('=');
        if (key)acc[key] = value;
        return acc;
      }, {});
    },
  },
};
</script>

<style lang="sass">
.chapters
  display: flex
  align-items: center
  gap: 10px
  border-bottom: 2px solid #eee
  & > :first-child
    transform: translateY(2px)
  [data-theme='advanced']
    & .v-tab
      &[aria-selected="true"]
        background: white !important
  .v-slide-group__content
    align-items: center
</style>
