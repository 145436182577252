export const defaultTableBodyMixin = {
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    displayedItems: {
      type: Array,
      default: () => ([]),
    },
    displayedRawData: {
      type: Array,
      default: () => ([]),
    },
    payloadFields: {
      type: Array,
      default: () => ([]),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonlyRule: {
      type: Function,
      default: () => false,
    },
    deleteRule: {
      type: Function,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update', 'update:raw'],
  data() {
    return {
      page: 1,
      pageSize: 10,
      filterModel: {},
      allValues: {},
    };
  },
  computed: {
    filteredItems() {
      if (!this.fields.is_need_filter) return this.displayedItems;
      const appliedFilters = {};
      const filterArrayByFields = (object, array) => {
        const keys = Object.keys(object);
        return array.filter((item) => keys.every((key) => object[key].includes(item[key])));
      };

      Object.entries(this.filterModel).forEach(([key, val]) => {
        const searchItems = this.payloadFields[key].values?.filter((el) => el?.search?.find((s) => s?.includes(val)));
        if (searchItems && searchItems.length) {
          appliedFilters[key] = searchItems.map((el) => el.id);
        }
      });
      return filterArrayByFields(appliedFilters, this.displayedItems);
    },
    paginatedItems: {
      get() {
        if (!this.fields.is_need_pagination) return this.filteredItems;
        return this.filteredItems.slice((this.pageSize * (this.page - 1)), (this.pageSize * this.page));
      },
      set(val) {
        val.forEach((item) => {
          // eslint-disable-next-line no-underscore-dangle
          this.displayedItems[item._index] = item;
        });
      },
    },
    pagesTotal() {
      return Math.ceil(this.filteredItems.length / this.pageSize);
    },
    canSelectAll() {
      return Object.entries(this.fields).some((f) => f[1]?.can_set_all);
    },
  },
  methods: {
    onSetWatchQuery({ field, value }) {
      this.$emit('setWatchQuery', { field, value });
    },
    onUpdate({ field, item }) {
      if (field?.[1].request_on_change && field?.[0] && (item[field[0]] || item[field[0]] === false)) {
        this.$emit('setWatchQuery', { field: field[0], value: item[field[0]] });
      }
      this.$emit('update');
    },
    setAllValues({ field, value }) {
      this.$emit('setAllValues', { field, value });
    },
    onUpdateRaw(
      ...e
    ) {
      this.$emit('update:raw', ...e);
    },
    getRawItem(idx) {
      if (!this.displayedRawData) return null;
      // eslint-disable-next-line no-debugger
      return this.displayedRawData?.find((el, i) => i === idx);
    },
    del(idx) {
      this.$emit('delete', idx);
    },
  },
};