<template>
  <v-card>
    <v-card-title>Распределить товары</v-card-title>
    <v-list class="productList">
      <v-list-item
        v-for="(pr, i) of products"
        :key="i"
        :active="selectedProductsIds.includes(pr.organization_product?.id)"
      >
        <div
          class="productList_item"
        >
          <a
            class="line-clamp-3 productList_item__caption"
            :href="`/organizationProduct/show/${pr.organization_product_from_id ?? pr.organization_product_id}`"
            target="_blank"
          >
            {{ pr.organization_product?.name || getProductName(pr.organization_product_from_id ?? pr.organization_product_id) }}
          </a>
          <v-text-field
            v-model="pr.count"
            type="number"
            hide-details
            density="compact"
            :disabled="pr.count_not_distributed === 0"
            class="productList_item__input"
          />
          <div style="width: 80px; text-align: end; font-size: 12px">
            {{ pr.count_not_distributed }} шт. доступно
          </div>
        </div>
      </v-list-item>
    </v-list>
    <p v-if="!products.length">
      Не добавлено планируемых товаров
    </p>
    <v-card-actions>
      <v-spacer />
      <v-btn
        variant="flat"
        color="primary"
        size="small"
        :disabled="!selectedProducts.length"
        @click="addProducts"
      >
        Добавить
      </v-btn>
      <v-btn
        color="primary"
        variant="tonal"
        size="small"
        @click="$emit('close')"
      >
        Отмена
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'AddProductsModal',
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Object,
      default: () => {},
    },
    plannedProducts: {
      type: Array,
      default: () => ([]),
    },
  },
  emits: ['addProducts'],
  data() {
    return {
      products: [],
    };
  },
  computed: {
    availableProducts() {
      return this.products.filter((pr) => pr.count_not_distributed > 0);
    },
    selectedProducts() {
      return this.products.filter((pr) => pr.count > 0 && pr.count <= pr.count_not_distributed);
    },
    selectedProductsIds() {
      return this.selectedProducts.filter((pr) => pr.count > 0).map((pr) => pr.organization_product.id);
    },
  },
  beforeMount() {
    this.products = this.plannedProducts.reduce((acc, el) => {
      acc.push(...el.session_products.map((pr) => ({
        ...el, ...pr, main_organization_product: el.main_organization_product, organization_product_id: el.organization_product_id,
      })));
      return acc;
    }, []);
  },
  methods: {
    addProducts() {
      this.$emit('addProducts', this.selectedProducts);
    },
    getProductName(id) {
      return this.fields.organization_product_id?.values.find((el) => el.id === id)?.name;
    },
  },
};
</script>

<style lang="sass" scoped>
.productList
  &_item
    display: grid
    grid-template-columns: 70% 15% 15%
    align-items: center
    &__caption
      font-size: 12px
      line-height: 14px
      color: var(--dark-grey)
      cursor: pointer
      &:hover
        text-decoration: underline
    &__input
      max-width: 150px
</style>