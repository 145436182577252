import { generateEnityRoutes } from '@/utils/utils';

export default [
  {
    path: '/',
    name: 'main',
    component: () => import('@/layouts/Main.vue'),
    meta: {
      title: 'mainLayout',
      auth: true,
    },
    children: [
      {
        path: '',
        name: '-',
        redirect: '/profile',
      },
      // {
      //   path: '/dashboard',
      //   name: 'dashboard',
      //   component: () => import('@/pages/dashboard/dashboard.vue'),
      // },
      {
        path: '/payment/extend',
        name: 'extend',
        component: () => import('@/pages/payment/PaymentExtend.vue'),
      },
      {
        path: '/payment/tariffs',
        name: 'tariffs',
        component: () => import('@/pages/payment/Tariffs.vue'),
      },
      {
        path: '/payment/success',
        name: 'success',
        component: () => import('@/pages/payment/PaymentSuccess.vue'),
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/pages/profile/profile.vue'),
        children: [
          {
            path: '',
            redirect: '/profile/account/main',
          },
          {
            path: 'account/main',
            name: 'account',
            component: () => import('@/pages/profile/views/account'),
          },
          {
            path: 'registrationLinkCompany',
            name: 'registrationLinkCompany',
            component: () => import('@/pages/profile/views/company'),
            meta: { tab: 'registrationLinkCompany' },
            children: [
              {
                path: 'main',
                redirect: '/profile/company/list',
                meta: { tab: 'registrationLinkCompany' },
              },
              {
                path: 'list',
                name: 'registrationLinkCompanyList',
                component: () => import('@/pages/profile/views/company/components/list'),
                meta: { tab: 'registrationLinkCompany' },
              },
              {
                path: 'archive',
                name: 'registrationLinkCompanyArchive',
                component: () => import('@/pages/profile/views/company/components/archive'),
                meta: { tab: 'registrationLinkCompany' },
              },
              {
                path: 'create',
                name: 'registrationLinkCompanyCreate',
                component: () => import('@/pages/profile/views/company/components/create'),
                meta: { tab: 'registrationLinkCompany' },
              },
              {
                path: 'edit/:id',
                name: 'registrationLinkCompanyEdit',
                component: () => import('@/pages/profile/views/company/components/edit'),
                meta: { tab: 'registrationLinkCompany' },
              },
              {
                path: 'show/:id',
                name: 'registrationLinkCompanyShow',
                component: () => import('@/pages/profile/views/company/components/show'),
                meta: { tab: 'registrationLinkCompany' },
              },
            ],
          },
          {
            path: 'integrationApiToken',
            name: 'integrationApiToken',
            component: () => import('@/pages/profile/views/integrationApiToken'),
            meta: { tab: 'integrationApiToken' },
            children: [
              {
                path: 'main',
                redirect: '/profile/integrationApiToken/list',
                meta: { tab: 'integrationApiToken' },
              },
              {
                path: 'list',
                name: 'integrationApiTokenList',
                component: () => import('@/pages/profile/views/integrationApiToken/components/list'),
                meta: { tab: 'integrationApiToken' },
              },
              {
                path: 'archive',
                name: 'integrationApiTokenArchive',
                component: () => import('@/pages/profile/views/integrationApiToken/components/archive'),
                meta: { tab: 'integrationApiToken' },
              },
              {
                path: 'create',
                name: 'integrationApiTokenCreate',
                component: () => import('@/pages/profile/views/integrationApiToken/components/create'),
                meta: { tab: 'integrationApiToken' },
              },
              {
                path: 'edit/:id',
                name: 'integrationApiTokenEdit',
                component: () => import('@/pages/profile/views/integrationApiToken/components/edit'),
                meta: { tab: 'integrationApiToken' },
              },
              {
                path: 'show/:id',
                name: 'integrationApiTokenShow',
                component: () => import('@/pages/profile/views/integrationApiToken/components/show'),
                meta: { tab: 'integrationApiToken' },
              },
            ],
          },
          {
            path: 'company',
            name: 'company',
            component: () => import('@/pages/profile/views/company'),
            meta: { tab: 'company' },
            children: [
              {
                path: 'main',
                redirect: '/profile/company/list',
                meta: { tab: 'company' },
              },
              {
                path: 'list',
                name: 'companyList',
                component: () => import('@/pages/profile/views/company/components/list'),
                meta: { tab: 'company' },
              },
              {
                path: 'archive',
                name: 'companyArchive',
                component: () => import('@/pages/profile/views/company/components/archive'),
                meta: { tab: 'company' },
              },
              {
                path: 'create',
                name: 'companyCreate',
                component: () => import('@/pages/profile/views/company/components/create'),
                meta: { tab: 'company' },
              },
              {
                path: 'edit/:id',
                name: 'companyEdit',
                component: () => import('@/pages/profile/views/company/components/edit'),
                meta: { tab: 'company' },
              },
              {
                path: 'show/:id',
                name: 'companyShow',
                component: () => import('@/pages/profile/views/company/components/show'),
                meta: { tab: 'company' },
              },
            ],
          },
          {
            path: 'companyInfo/main',
            name: 'companyInfo',
            component: () => import('@/pages/profile/views/companyInfo'),
          },
          {
            path: 'marketPlace',
            name: 'marketPlace',
            component: () => import('@/pages/profile/views/marketPlaces'),
            children: [
              {
                path: 'main',
                redirect: '/profile/marketPlace/list',
              },
              {
                path: 'list',
                name: 'marketPlaceList',
                component: () => import('@/pages/profile/views/marketPlaces/components/list'),
              },
              {
                path: 'archive',
                name: 'marketPlaceArchive',
                component: () => import('@/pages/profile/views/marketPlaces/components/archive'),
              },
              {
                path: 'create',
                name: 'marketPlaceCreate',
                component: () => import('@/pages/profile/views/marketPlaces/components/create'),
              },
              {
                path: 'edit/:id',
                name: 'marketPlaceEdit',
                component: () => import('@/pages/profile/views/marketPlaces/components/edit'),
              },
              {
                path: 'show/:id',
                name: 'marketPlaceShow',
                component: () => import('@/pages/profile/views/marketPlaces/components/show'),
              },
            ],
          },
          {
            path: 'organization',
            name: 'organization',
            component: () => import('@/pages/profile/views/organizations'),
            children: [
              {
                path: 'main',
                redirect: '/profile/organization/list',
              },
              {
                path: 'list',
                name: 'organizationList',
                component: () => import('@/pages/profile/views/organizations/components/list'),
              },
              {
                path: 'archive',
                name: 'organizationArchive',
                component: () => import('@/pages/profile/views/organizations/components/archive'),
              },
              {
                path: 'create',
                name: 'organizationCreate',
                component: () => import('@/pages/profile/views/organizations/components/create'),
              },
              {
                path: 'edit/:id',
                name: 'organizationEdit',
                component: () => import('@/pages/profile/views/organizations/components/edit'),
              },
              {
                path: 'show/:id',
                name: 'organizationShow',
                component: () => import('@/pages/profile/views/organizations/components/show'),
              },
            ],
          },
          {
            path: 'role',
            name: 'role',
            component: () => import('@/pages/profile/views/role'),
            children: [
              {
                path: 'main',
                redirect: '/profile/role/list',
              },
              {
                path: 'list',
                name: 'roleList',
                component: () => import('@/pages/profile/views/role/components/list'),
              },
              {
                path: 'archive',
                name: 'roleArchive',
                component: () => import('@/pages/profile/views/role/components/archive'),
              },
              {
                path: 'create',
                name: 'roleCreate',
                component: () => import('@/pages/profile/views/role/components/create'),
              },
              {
                path: 'edit/:id',
                name: 'roleEdit',
                component: () => import('@/pages/profile/views/role/components/edit'),
              },
              {
                path: 'show/:id',
                name: 'roleShow',
                component: () => import('@/pages/profile/views/role/components/show'),
              },
            ],
          },
          {
            path: 'jobTitle',
            name: 'jobTitle',
            component: () => import('@/pages/profile/views/jobTitle'),
            children: [
              {
                path: 'main',
                redirect: '/profile/jobTitle/list',
              },
              {
                path: 'list',
                name: 'jobTitleList',
                component: () => import('@/pages/profile/views/jobTitle/components/list'),
              },
              {
                path: 'archive',
                name: 'jobTitleArchive',
                component: () => import('@/pages/profile/views/jobTitle/components/archive'),
              },
              {
                path: 'create',
                name: 'jobTitleCreate',
                component: () => import('@/pages/profile/views/jobTitle/components/create'),
              },
              {
                path: 'edit/:id',
                name: 'jobTitleEdit',
                component: () => import('@/pages/profile/views/jobTitle/components/edit'),
              },
              {
                path: 'show/:id',
                name: 'jobTitleShow',
                component: () => import('@/pages/profile/views/jobTitle/components/show'),
              },
            ],
          },
          {
            path: 'storage',
            name: 'storage',
            component: () => import('@/pages/profile/views/storages'),
            children: [
              {
                path: 'main',
                redirect: '/profile/storage/list',
              },
              {
                path: 'list',
                name: 'storageList',
                component: () => import('@/pages/profile/views/storages/components/list'),
              },
              {
                path: 'archive',
                name: 'storageArchive',
                component: () => import('@/pages/profile/views/storages/components/archive'),
              },
              {
                path: 'create',
                name: 'storageCreate',
                component: () => import('@/pages/profile/views/storages/components/create'),
              },
              {
                path: 'edit/:id',
                name: 'storageEdit',
                component: () => import('@/pages/profile/views/storages/components/edit'),
              },
              {
                path: 'show/:id',
                name: 'storageShow',
                component: () => import('@/pages/profile/views/storages/components/show'),
              },
            ],
          },
          {
            path: 'user',
            name: 'user',
            component: () => import('@/pages/profile/views/users'),
            children: [
              {
                path: 'main',
                redirect: '/profile/user/list',
                meta: { tab: 'user' },
              },
              {
                path: 'list',
                name: 'usersList',
                component: () => import('@/pages/profile/views/users/components/list'),
                meta: { tab: 'user' },
              },
              {
                path: 'archive',
                name: 'usersArchive',
                component: () => import('@/pages/profile/views/users/components/archive'),
                meta: { tab: 'user' },
              },
              {
                path: 'create',
                name: 'usersCreate',
                component: () => import('@/pages/profile/views/users/components/create'),
                meta: { tab: 'user' },
              },
              {
                path: 'edit/:id',
                name: 'usersEdit',
                component: () => import('@/pages/profile/views/users/components/edit'),
                meta: { tab: 'user' },
              },
              {
                path: 'show/:id',
                name: 'usersShow',
                component: () => import('@/pages/profile/views/users/components/show'),
                meta: { tab: 'user' },
              },
            ],
          },
          {
            path: 'workers',
            name: 'workers',
            component: () => import('@/pages/profile/views/users'),
            children: [
              {
                path: 'main',
                redirect: '/profile/workers/list',
                meta: { tab: 'workers' },
              },
              {
                path: 'list',
                name: 'workersList',
                component: () => import('@/pages/profile/views/users/components/list'),
                meta: { tab: 'workers' },
              },
              {
                path: 'archive',
                name: 'workersArchive',
                component: () => import('@/pages/profile/views/users/components/archive'),
                meta: { tab: 'workers' },
              },
              {
                path: 'create',
                name: 'workersCreate',
                component: () => import('@/pages/profile/views/users/components/create'),
                meta: { tab: 'workers' },
              },
              {
                path: 'edit/:id',
                name: 'workersEdit',
                component: () => import('@/pages/profile/views/users/components/edit'),
                meta: { tab: 'workers' },
              },
              {
                path: 'show/:id',
                name: 'workersShow',
                component: () => import('@/pages/profile/views/users/components/show'),
                meta: { tab: 'workers' },
              },
            ],
          },
          {
            path: 'regLinks',
            name: 'regLinks ',
            component: () => import('@/pages/profile/views/users'),
            children: [
              {
                path: 'main',
                redirect: '/profile/regLinks/list',
                meta: { tab: 'regLinks' },
              },
              {
                path: 'list',
                name: 'regLinksList',
                component: () => import('@/pages/profile/views/users/components/list'),
                meta: { tab: 'regLinks' },
              },
              {
                path: 'archive',
                name: 'regLinksArchive',
                component: () => import('@/pages/profile/views/users/components/archive'),
                meta: { tab: 'regLinks' },
              },
              {
                path: 'create',
                name: 'regLinksCreate',
                component: () => import('@/pages/profile/views/users/components/create'),
                meta: { tab: 'regLinks' },
              },
              {
                path: 'edit/:id',
                name: 'regLinksEdit',
                component: () => import('@/pages/profile/views/users/components/edit'),
                meta: { tab: 'regLinks' },
              },
              {
                path: 'show/:id',
                name: 'regLinksShow',
                component: () => import('@/pages/profile/views/users/components/show'),
                meta: { tab: 'regLinks' },
              },
            ],
          },
          {
            path: 'subscription/main',
            name: 'subscription',
            component: () => import('@/pages/profile/views/subscription'),
          },
          {
            path: 'systemSettings/main',
            name: 'systemSettings',
            component: () => import('@/pages/profile/views/systemSettings'),
          },
        ],
      },
      {
        path: '/pdf/:entity/:id',
        name: 'pdf',
        component: () => import('@/pages/pdf'),
      },
      {
        path: '/file/:entity/:id',
        name: 'file',
        component: () => import('@/pages/file'),
      },
      {
        path: '/excel/:entity/:id',
        name: 'file',
        component: () => import('@/pages/excel'),
      },
      {
        path: '/techCard/create/:organizationProductId',
        name: 'techCardCreate',
        component: () => import('@/pages/techCard/create/techCard.create.vue'),
      },
      {
        path: '/notFiledWorkerRecord/list',
        name: 'notFiledWorkerRecordList',
        component: () => import('@/pages/notFiledWorkerRecord/index.vue'),
      },
      {
        path: '/workerProfile/list',
        name: 'workerProfileList',
        component: () => import('@/pages/workerProfile/list/index.vue'),
      },
      {
        path: '/workerProfile/show/:workerId',
        name: 'workerProfileShow',
        component: () => import('@/pages/workerProfile/show/index.vue'),
      },
      {
        path: '/requirements',
        component: () => import('@/pages/requirements/index.vue'),
      },
      {
        path: 'qrJobTitle/list',
        component: () => import('@/pages/qrJobTitle/index.vue'),
      },
      // admin
      ...generateEnityRoutes('acceptance'),
      ...generateEnityRoutes('box'),
      ...generateEnityRoutes('boxCreation'),
      ...generateEnityRoutes('boxPrint'),
      ...generateEnityRoutes('product'),
      ...generateEnityRoutes('organizationProduct'),
      ...generateEnityRoutes('documentOrganizationProduct'),
      ...generateEnityRoutes('organizationProductMarked'),
      ...generateEnityRoutes('marketplaceCard'),
      ...generateEnityRoutes('consignment'),
      ...generateEnityRoutes('disposal'),
      ...generateEnityRoutes('defectProduct'),
      ...generateEnityRoutes('inventory'),
      ...generateEnityRoutes('organizationChanger'),
      ...generateEnityRoutes('pDFFile'),
      ...generateEnityRoutes('remnant'),
      ...generateEnityRoutes('remnantsChangerUpper'),
      ...generateEnityRoutes('size'),
      ...generateEnityRoutes('jobTitle'),
      ...generateEnityRoutes('jobService'),
      ...generateEnityRoutes('sessionSettings'),
      // finance
      ...generateEnityRoutes('balance'),
      ...generateEnityRoutes('completedJob'),
      ...generateEnityRoutes('dDT'),
      ...generateEnityRoutes('dDS'),
      ...generateEnityRoutes('job'),
      ...generateEnityRoutes('jobAnother'),
      ...generateEnityRoutes('transaction'),
      ...generateEnityRoutes('storageChangerMain'),
      ...generateEnityRoutes('remnantChanger'),
      ...generateEnityRoutes('storageChangerUpper'),
      {
        path: '/workerList/list',
        name: 'workerList',
        component: () => import('@/pages/workerList/list/workerList.list.vue'),
      },
      // report
      ...generateEnityRoutes('document'),
      ...generateEnityRoutes('documentSession'),
      ...generateEnityRoutes('documentVolume'),
      ...generateEnityRoutes('report'),
      // shipmentFBO
      ...generateEnityRoutes('shipment'),
      {
        path: '/shipment/calendar',
        name: 'shipmentCalendar',
        component: () => import('@/pages/shipment/calendar/index.vue'),
      },
      // productionOrder
      ...generateEnityRoutes('productionOrder'),
      // shipmentFBS
      ...generateEnityRoutes('markingFBS'),
      ...generateEnityRoutes('fBSOrder'),
      ...generateEnityRoutes('shipmentFBS'),
      ...generateEnityRoutes('fbsSettings'),
      // marking
      ...generateEnityRoutes('marking'),
      ...generateEnityRoutes('wbDeliveryPredict'),
    ],
  },
  {
    path: '/auth',
    component: () => import('@/layouts/Login.vue'),
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/pages/login/login.vue'),
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/pages/register/register.vue'),
      },
    ],
  },
  {
    path: '/offer',
    component: () => import('@/pages/offer/index.vue'),
  },
  {
    path: '/terminal-app/apk/download',
    component: () => import('@/pages/downloadApp/index.vue'),
  },
];
