<template>
  <div class="productPhoto_wrapper">
    <img
      alt="Фото"
      class="productPhoto"
      :src="url"
      @click="isPopup = true"
    >
    <v-dialog
      v-model="isPopup"
      width="1200"
    >
      <v-card
        class="pa-0"
        @click="isPopup = false"
      >
        <img
          class="photo"
          :src="url || '/img/emptyImage.jpg'"
          @click.stop="isPopup = false"
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isPopup: false,
    };
  },
};
</script>

<style lang="sass" scoped>
.productPhoto
  width: 30px
  height: 40px
  object-fit: cover
  border-radius: 5px
  cursor: pointer
</style>