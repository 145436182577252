<template>
  <thead>
    <tr>
      <th
        v-for="(field, i) of Object.entries(payloadFields)"
        :key="i"
        class="text-left"
        style="min-width: 200px"
      >
        {{ field[1].label }}
      </th>
      <th
        v-if="!is_chapter"
        class="text-left"
      />
    </tr>
  </thead>
</template>

<script>
export default {
  name: 'DefaultTableHead',
  props: {
    is_chapter: {
      type: Boolean,
      default: false,
    },
    payloadFields: {
      type: Array,
      default: () => ([]),
    },
  },
};
</script>