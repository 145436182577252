<template>
  <v-text-field
    :model-value="formattedDate"
    placeholder="Дата"
    :density="density"
    :variant="variant"
    :label="label"
    hide-details
    color="primary"
    :clearable="clearable"
    :disabled="disabled"
  >
    <v-menu
      activator="parent"
      :close-on-content-click="closeOnClick"
      :lang="'ru'"
      :width="360"
      :max-width="328"
      :min-width="328"
      transition="none"
    >
      <v-card class="pa-0">
        <v-locale-provider locale="ru">
          <v-date-picker
            v-model="date"
            :max-width="328"
            color="primary"
            hide-actions
            title="Выберите дату"
            :allowed-dates="allowedDates"
            @update:modelValue="onDateChange"
          >
            <template #header />
          </v-date-picker>
        </v-locale-provider>

        <TimePicker
          v-if="time"
          :model-value="date?.toLocaleTimeString('ru', {hour: '2-digit', minute: '2-digit'}) || ''"
          class="mb-2 mx-2"
          label="Время"
          :variant="'outlined'"
          @update:modelValue="onTimeChanged"
        />
      </v-card>
    </v-menu>
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner">
      </slot>
    </template>

    <template
      v-if="$slots['append-inner']"
      #append-inner
    >
      <slot name="append-inner">
      </slot>
    </template>
  </v-text-field>
</template>

<script>
import TimePicker from '@/components/UiKit/Form/TimePicker/index.vue';
import { transformFormattedDate } from '@/utils/utils';

export default {
  name: 'DatePicker',
  components: { TimePicker },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    closeOnClick: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    density: {
      type: String,
      default: 'compact',
    },
    variant: {
      type: String,
      default: 'filled',
    },
    setDefaultValue: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    allowedDates: {
      type: Function,
      default: () => true,
    },
  },
  data() {
    return {
      date: [],
      hours: null,
      minutes: null,
    };
  },
  computed: {
    formattedDate() {
      if (!this.date) return '';
      if (Array.isArray(this.date) && !this.date.length) return '';
      if (!this.time) {
        return this.date.toLocaleDateString('ru');
      }
      return this.date.toLocaleDateString('ru', {
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },
  watch: {
    modelValue() {
      if (typeof this.modelValue?.includes === 'function' && this.modelValue?.includes('.')) this.date = new Date(transformFormattedDate(this.modelValue));
      else if (this.modelValue) this.date = new Date(this.modelValue);
      else this.date = null;
    },
  },
  beforeMount() {
    if (this.modelValue) {
      this.date = new Date(this.modelValue);
      this.hours = this.date.getHours();
      this.minutes = this.date.getMinutes();
    } else if (this.setDefaultValue) {
      this.date = new Date();
    }
  },
  methods: {
    onDateChange(val) {
      const nd = new Date(val);
      nd.setHours(this.hours);
      nd.setMinutes(this.minutes);
      this.$emit('update:modelValue', this.formatDate(nd));
    },
    onTimeChanged(val) {
      const [hours, minutes] = val.split(':');
      this.hours = hours;
      this.minutes = minutes;
      const d = new Date(this.date);
      d.setHours(hours);
      d.setMinutes(minutes);
      this.date = d;
      this.onDateChange(this.date);
    },
    formatDate(d) {
      return new Date(d).toLocaleDateString('ru', this.time ? { hour: '2-digit', minute: '2-digit' } : {});
    },
  },
};
</script>

<style lang="sass" scoped>
.dropdown.drop-down
  position: fixed !important
  top: 100% !important
</style>