import { columns } from '@/store/modules/workerList/columns';
import { DefaultEntityStore } from '@/store/defaultEntityStore';

const store = new DefaultEntityStore('workerList');

const state = {
  ...store.state,
  columns,
  isEdit: false,
  qr: '',
  notActiveWorkers: [],
  notActiveOrganizations: [],
};
const getters = {
  ...store.getters,
  qr: (st) => st.qr,
  notActiveWorkers: (st) => st.notActiveWorkers,
  notActiveOrganizations: (st) => st.notActiveOrganizations,
};
const actions = {
  // eslint-disable-next-line no-unused-vars
  getList({ commit, dispatch }, { date, organizationId }) {
    let url = 'api/workRecord/workers/day?';
    if (date) url += `date=${date}&`;
    if (organizationId) url += `organization_id=${organizationId}&`;
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: url,
      }, { root: true })
        .then(async (res) => {
          commit('setList', res.data.data);
          resolve({ rowData: res.data.data, totalCount: res.data.data.length, fields: [] });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  getQr({ commit, dispatch }, date) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: 'api/job-title/qrcode',
      }, { root: true })
        .then(async (res) => {
          commit('setQr', res.data.data.qrBase64);
          resolve(res.data.data.qrBase64);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getNotActiveWorkers({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: 'api/workRecord/workers/not-active',
      }, { root: true })
        .then(async (res) => {
          commit('setNotActiveWorkers', res.data.data.workers);
          commit('setNotActiveOrganizations', res.data.data.organizations);
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  startWorkerJob({ dispatch }, { jobTitleId, workerId, organizationId }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `api/workRecord/job-title/start/${jobTitleId}?workerId=${workerId}&organization_id=${organizationId}`,
      }, { root: true })
        .then(async (res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // eslint-disable-next-line no-unused-vars
  setStatus({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: `api/workRecord/active-job-title/${payload.status}?workerId=${payload.workerId}`,
      }, { root: true })
        .then(async () => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
const mutations = {
  ...store.mutations,
  // eslint-disable-next-line no-unused-vars
  setQr(st, payload) {
    st.qr = payload;
  },
  setNotActiveWorkers(st, payload) {
    st.notActiveWorkers = payload;
  },
  setNotActiveOrganizations(st, payload) {
    st.notActiveOrganizations = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
