export default [
  {
    title: 'Новая',
    name: 'advanced',
    dark: false,
    showTooltip: false,
    showBgImage: true,
    formFieldVariant: 'outlined',
    sidebarWidth: 310,
    colors: {
      info: '#747af2',
      dark: '#272b3d',
      'dark-1': '#1f2234',
      background: '#F2F2F2',
      surface: '#FFFFFF',
      primary: '#2757FC',
      secondary: '#DCEFFF',
      error: '#fc2727',
      darkRed: '#9a1919',
      pink: '#FEB4FF',
      purple: '#A16CFF',
      green: '#0AD105',
      orange: '#F98D1E',
      warning: '#FFBE5B',
      'warning-dark': '#975B00',
      success: '#4ADA85',
      'success-dark': '#027D1D',

      sidebar: '#FFFFFF',
      sidebarBtn: '#2757FC',
    },
  },
  {
    title: 'Классика',
    name: 'classic',
    dark: false,
    showTooltip: true,
    showBgImage: false,
    formFieldVariant: 'filled',
    sidebarWidth: 250,
    colors: {
      background: '#f0f4f7',
      surface: '#FFFFFF',
      primary: '#3399ff',
      secondary: '#ebedef',
      error: '#F44336',
      info: '#747af2',
      success: '#00C853',
      warning: '#efc41b',
      dark: '#272b3d',
      'dark-1': '#1f2234',
      pink: '#e4507d',

      sidebar: '#1f2234',
      sidebarBtn: '#FFFFFF',
    },
  },
  {
    title: 'Классика (темная)',
    name: 'classicDark',
    dark: true,
    showTooltip: true,
    showBgImage: false,
    formFieldVariant: 'filled',
    sidebarWidth: 250,
    colors: {
      background: '#272b3d',
      surface: '#1f2234',
      primary: '#3399ff',
      secondary: '#ebedef',
      error: '#F44336',
      info: '#747af2',
      success: '#00C853',
      warning: '#efc41b',
      dark: '#272b3d',
      'dark-1': '#1f2234',
      pink: '#e4507d',

      sidebar: '#1f2234',
      sidebarBtn: '#FFFFFF',
    },
  },
];