export default {
  namespaced: true,
  state: {
    payload: {},
    fileData: {
      file: null,
      entity: null,
      field: null,
      extension: null,
    },
  },
  mutations: {
    setPayload(state, { payload, storeModule }) {
      state.payload[storeModule] = payload;
    },
    clearPayload(state, storeModule) {
      if (storeModule) delete state.payload[storeModule];
      else state.payload = {};
    },
    setPayloadField(state, { storeModule, key, value }) {
      if (state.payload[storeModule]) state.payload[storeModule][key] = value;
      console.log('PAYL', state.payload[storeModule]);
    },
    setFileData(state, value) {
      state.fileData = value;
    },
  },
  getters: {
    payload: (state) => state.payload,
    fileData: (state) => state.fileData,
  },
};
