<template>
  <div class="listChapters">
    <v-tabs
      v-model="tab"
      color="primary"
      @update:modelValue="$emit('update:modelValue', tab)"
    >
      <v-tab
        v-for="(chapter, i) of chapters"
        :key="i"
        :value="chapter.value"
      >
        {{ chapter.label }}
      </v-tab>
    </v-tabs>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    chapters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tab: '',
    };
  },
  watch: {
    modelValue() {
      this.tab = structuredClone(this.modelValue);
    },
  },
  beforeMount() {
    this.tab = structuredClone(this.modelValue);
  },
};
</script>

<style lang="sass">
.listChapters
  display: flex
  align-items: center
  gap: 10px
[data-theme='advanced']
  .listChapters
    .v-slide-group__container
      border-radius: 15px 15px 0 0
      background: rgba(220, 239, 255, 0.68) !important
    & .v-tab
      &[aria-selected="true"]
        background: white !important
        .v-tab__slider
          display: none
</style>