<template>
  <div class="workRecordsEdit text-start">
    <Label>
      <template #default>
        {{ label }}
      </template>
      <template
        v-if="$slots['hint']"
        #hint
      >
        <slot name="hint"></slot>
      </template>
    </Label>
    <v-table class="defaultTable">
      <thead>
        <tr>
          <th style="min-width: 200px">
            Работа
          </th>
          <th style="min-width: 200px">
            Рабочие
          </th>
          <th />
        </tr>
      </thead>
      <tbody v-if="fields">
        <template
          v-for="(item, i) of items"
        >
          <tr
            v-if="item.id >= 0"
            :key="i"
          >
            <td style="width: 15%; min-width: 100px">
              <UiSelectSearch
                :model-value="item.job_id"
                :items="fields['job_id'].values"
                item-title="name"
                item-value="id"
                :no-data-text="'Нет данных'"
                density="compact"
                @update:modelValue="onJobChange(item, $event)"
              />
            </td>
            <td>
              <DefaultTableEdit
                v-model="item.work_user_records"
                :fields="innerFields"
                style="min-width: 200px"
                :disabled="!item.job_id"
                :empty-element-add="emptyElementInner"
                @update:modelValue="updateInnerValue()"
              >
                <template #col.worker_id="{item: innerItem, field, update}">
                  <WorkerPicker
                    v-model="innerItem[field[0]]"
                    :items="getWorkersFromJobId(item.job_id)"
                    :disabled="innerItem.status && innerItem.status !== 'planned'"
                    @update:modelValue="update({field, item: innerItem}); updateInnerValue()"
                  />
                </template>
                <template #col.work_user_records="{item: innerItem, idx}">
                  <div>
                    <v-btn
                      v-if="innerItem.status === 'closed'"
                      size="x-small"
                      color="primary"
                      @click="openEditModal(innerItem, item, i, idx)"
                    >
                      Заполнить
                    </v-btn>
                  </div>
                </template>
              </DefaultTableEdit>
            </td>
            <td>
              <v-btn
                size="extra-small"
                class="text-caption px-2"
                color="error"
                @click="del(i)"
              >
                X
              </v-btn>
            </td>
          </tr>
        </template>
      </tbody>
    </v-table>
    <div class="d-flex justify-center">
      <v-btn
        icon="mdi-plus"
        color="primary"
        size="small"
        class="my-4"
        @click="add"
      />
    </div>
    <v-dialog
      v-model="isPopup"
      width="700"
    >
      <EditModal
        :fields="fields"
        :item="popupData.item"
        :outer-item="popupData.outerItem"
        @update:item="onItemInModalUpdate"
      />
    </v-dialog>
  </div>
</template>

<script>
import UiSelectSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import DefaultTableEdit from '@/components/Tables/DefaultTable/components/edit/DefaultTableEdit.vue';
import EditModal from './components/EditModal.vue';
import StatusPicker from '@/components/Tables/WorkRecords/edit/components/StatusPicker.vue';
import WorkerPicker from '@/components/Tables/WorkRecords/edit/components/WorkerPicker.vue';
import Label from '@/components/UiKit/Label/index.vue';

export default {
  name: 'WorkRecordsTableEdit',
  components: {
    Label,
    DefaultTableEdit,
    EditModal,
    StatusPicker,
    WorkerPicker,
    UiSelectSearch,
  },
  inject: ['storeModule'],
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      items: [],
      innerTablesItems: {},
      payloadFields: [],
      emptyElement: { id: 0 },
      excludedFields: ['types', 'label', 'field_type'],
      innerFields: {
        values: [],
        worker_id: {
          label: 'Рабочий⃰',
          require: true,
          values: [],
          types: [
            3,
          ],
          field_type: 'selectbox',
          priority: 10,
          is_visible_column: true,
        },
        status: {
          label: 'Статус',
          require: false,
          only_read: true,
          values: [
            {
              id: 'planned',
              name: 'Планируется',
            },
            {
              id: 'active',
              name: 'Активно',
            },
            {
              id: 'suspended',
              name: 'Приостановлено',
            },
            {
              id: 'closed',
              name: 'Закрыто',
            },
          ],
          types: [
            3,
          ],
          field_type: 'selectbox',
          priority: 5,
        },
        is_responsible: {
          label: 'Ответственный',
          values: [],
          types: [
            3,
          ],
          field_type: 'checkbox',
          priority: 99,
          is_visible_column: true,
        },
      },

      isPopup: false,
      popupType: '',
      popupData: {},
    };
  },
  computed: {
    emptyElementInner() {
      return {
        status: this.innerFields.status.values[0].id,
      };
    },
  },
  watch: {
    modelValue() {
      this.updateItems();
    },
  },
  beforeMount() {
    this.emptyElement = {
      id: 0,
      job_id: null,
      work_user_records: [],
    };
  },
  mounted() {
    this.initItems();
  },
  methods: {
    onJobChange(item, jobId) {
      const fromEmpty = item.job_id === null;
      const toEmpty = jobId === null;
      item.job_id = jobId;
      item.price = null;
      if (!fromEmpty && !toEmpty) item.work_user_records = [];
      this.updateInnerValue();
    },
    onJobChildChange(item, jobChildId) {
      const selectedJob = this.getSelectedJob(item);
      const jobChild = selectedJob.units.find((el) => el.id === jobChildId);
      item.price = jobChild?.price || 0;
      this.updateInnerValue();
    },
    getSelectedJob(item) {
      return this.fields.job_id.values.find((el) => el.id === item.job_id);
    },
    add() {
      this.items.push(structuredClone(this.emptyElement));
      this.$emit('update:modelValue', this.items);
    },
    del(idx) {
      this.items = this.items
        .map((el, i) => {
          if ((i === idx)) {
            if (el.id)el.id = -el.id;
            else el.id = 'del';
          }
          return el;
        })
        .filter((el) => el.id !== 'del');
      this.$emit('update:modelValue', this.items);
    },
    initItems() {
      if (this.modelValue) {
        this.items = JSON.parse(JSON.stringify(this.modelValue)).map((row) => {
          Object.entries(row).forEach((el) => {
            if (this.fields[el[0]]?.field_type === 'default_table') {
              row[el[0]] = Array.isArray(el[1]) ? el[1] : el[1].values;
            } else if (this.fields[el[0]]?.is_collection && el[1].values && el[1].values.length) {
              row[el[0]] = Array.isArray(el[1]) ? el[1].map((v) => v.id) : el[1].values.map((v) => v.id);
            } else if (el[1]?.value !== undefined) {
              row[el[0]] = el[1].value ?? el[1];
            }
            return el;
          });
          return row;
        });
      }

      this.$emit('update:modelValue', this.items);
    },
    updateItems() {
      this.items = JSON.parse(JSON.stringify(this.modelValue)).map((row) => {
        Object.entries(row).forEach((el) => {
          if (el[1]?.value !== undefined) {
            row[el[0]] = el[1].value;
          } else if (this.fields[el[0]]?.is_collection && el[1].values && el[1].values.length) {
            row[el[0]] = el[1].values.map((v) => v.id);
          }
          return el;
        });
        return row;
      });
    },
    updateInnerValue() {
      this.$emit('update:modelValue', this.items);
    },
    setInnerTableHeight({ tableHeight }, index) {
      this.innerTablesItems[index] = { tableHeight };
    },
    getUnitsFromJobId(id) {
      if (!id || !this.fields) return [];
      return this.fields.job_id.values.find((el) => el.id === id)?.units || [];
    },
    getWorkersFromJobId(id) {
      if (!this.fields) return [];
      return [...this.fields.job_id.values.find((el) => el.id === id)?.workers] || [];
    },
    getPriceFromJobId(id, unitId) {
      const units = this.getUnitsFromJobId(id);
      return units.find((el) => el.id === unitId)?.price;
    },
    getInnerItemByIdx(item, idx) {
      return item.work_user_records.find((el, i) => i === idx);
    },
    openEditModal(item, outerItem, idx, innerIdx) {
      this.isPopup = true;
      this.popupType = 'edit';
      this.popupData.item = item;
      this.popupData.itemIndex = idx;
      this.popupData.itemInnerIndex = innerIdx;
      this.popupData.outerItem = outerItem;
    },
    onItemInModalUpdate(data) {
      this.isPopup = false;
      const item = this.items.find((el, i) => i === this.popupData.itemIndex);
      if (!item) return;
      item.work_user_records = item.work_user_records.map((el, i) => {
        if (i === this.popupData.itemInnerIndex) {
          el = { ...el, ...data };
        }
        return el;
      });
      this.$emit('update:modelValue', this.items);
    },
  },
};
</script>

<style lang="sass" scoped>
.defaultTable
  border: 1px solid #eee
  border-radius: 5px
  margin-block: 10px
</style>