<template>
  <v-text-field
    v-model="value"
    height="20"
    :hide-details="hideDetails"
    variant="filled"
    :density="density"
    :disabled="disabled"
    :clearable="clearable"
    @update:modelValue="debouncedInput"
  >
    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner">
      </slot>
    </template>
    <template
      v-if="$slots['append-inner']"
      #append-inner
    >
      <slot name="append-inner">
      </slot>
    </template>
  </v-text-field>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'UiUnput',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    density: {
      type: String,
      default: 'compact',
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      value: '',
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
    },
  },
  beforeMount() {
    this.value = this.modelValue;
  },
  methods: {
    debouncedInput: _.debounce(function () {
      this.$emit('update:modelValue', this.value);
    }, 200),
  },
};
</script>