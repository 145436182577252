import { createStore } from 'vuex';
import axios from 'axios';

import ajax from '@/store/modules/ajax';
import barcode from '@/store/modules/barcode';
import modal from '@/store/modules/modal';
import dashboard from '@/store/modules/dashboard/dashboard';
import acceptance from '@/store/modules/acceptance';
import box from '@/store/modules/box';
import jobTitle from '@/store/modules/jobTitle';
import size from '@/store/modules/size';
import product from '@/store/modules/product';
import organizationProduct from '@/store/modules/organizationProduct';
import documentOrganizationProduct from '@/store/modules/documentOrganizationProduct/index';
import marketplaceCard from '@/store/modules/marketplaceCard';
import fbo from '@/store/modules/fbo';
import user from '@/store/modules/user';
import excel from '@/store/modules/excel';
import account from '@/store/modules/profile/account';
import employees from '@/store/modules/profile/employees/employees';
import workers from '@/store/modules/profile/workers/workers';
import organizations from '@/store/modules/profile/organizations/organizations';
import storages from '@/store/modules/profile/storages/storages';
import storageType from '@/store/modules/storageType';
import marketplaces from '@/store/modules/profile/marketplaces/marketplaces';
import registrationLink from '@/store/modules/profile/registrationLink/registrationLink';
import display from '@/store/modules/display';
import common from '@/store/modules/common';
import payload from '@/store/modules/payload/payload';
import role from '@/store/modules/profile/roles/roles';
import company from '@/store/modules/profile/company';
import registrationLinkCompany from '@/store/modules/profile/registrationLinkCompany';
import disposal from '@/store/modules/disposal';
import defectProduct from '@/store/modules/defectProduct';
import inventory from '@/store/modules/inventory';
import organizationChanger from '@/store/modules/organizationChanger';
import file from '@/store/modules/file';
import pDFFile from '@/store/modules/pDFFile';
import remnant from '@/store/modules/remnant';
import remnantsChangerUpper from '@/store/modules/remnantsChangerUpper';
import storageChangerMain from '@/store/modules/storageChangerMain';
import remnantChanger from '@/store/modules/remnantChanger';
import balance from '@/store/modules/balance';
import completedJob from '@/store/modules/completedJob';
import dDT from '@/store/modules/dDT';
import dDS from '@/store/modules/dDS';
import workerRecord from '@/store/modules/notFiledWorkerRecord';
import workerProfile from '@/store/modules/workerProfile';
import job from '@/store/modules/job';
import jobAnother from '@/store/modules/jobAnother';
import jobService from '@/store/modules/jobService';
import workerList from '@/store/modules/workerList';
import transaction from '@/store/modules/transaction';
import document from '@/store/modules/document';
import documentVolume from '@/store/modules/documentVolume';
import documentSession from '@/store/modules/documentSession';
import report from '@/store/modules/report';
import shipment from '@/store/modules/shipment';
import shipmentDraft from '@/store/modules/shipment/draft';
import driverCar from '@/store/modules/driver_car';
import driver from '@/store/modules/driver';
import productionOrder from '@/store/modules/productionOrder';
import markingFBS from '@/store/modules/markingFBS';
import fbsOrder from '@/store/modules/fBSOrder';
import shipmentFBS from '@/store/modules/shipmentFBS';
import fbsSettings from '@/store/modules/fbsSettings';
import payment from '@/store/modules/payment/payment';
import tariffs from '@/store/modules/payment/tariffs';
import consignment from '@/store/modules/consignment';
import requirements from '@/store/modules/requirements';
import organizationProductMarked from '@/store/modules/organizationProductMarked';
import marking from '@/store/modules/marking';
import boxCreation from '@/store/modules/boxCreation';
import integrationApiToken from '@/store/modules/integrationApiToken';
import wbDeliveryPredict from '@/store/modules/wbDeliveryPredict';
import analyzerSettings from '@/store/modules/analyzerSettings';
import pdf from '@/store/modules/pdf';
import boxPrint from '@/store/modules/boxPrint';
import sessionSettings from '@/store/modules/sessionSettings';

axios.interceptors.response.use(
  (resp) => Promise.resolve(resp),
  // eslint-disable-next-line consistent-return
  (err) => Promise.reject(err),
);
const modules = {
  ajax,
  barcode,
  boxCreation,
  modal,
  dashboard,
  fbo,
  auth: user,
  excel,
  // profile
  account,
  workers,
  user: employees,
  organization: organizations,
  storage: storages,
  storageType,
  marketPlace: marketplaces,
  regLinks: registrationLink,
  display,
  common,
  payload,
  role,
  jobTitle,
  jobService,
  company,
  registrationLinkCompany,
  integrationApiToken,
  // admin
  acceptance,
  box,
  boxPrint,
  size,
  product,
  organizationProduct,
  organizationProductMarked,
  documentOrganizationProduct,
  marketplaceCard,
  consignment,
  disposal,
  defectProduct,
  inventory,
  organizationChanger,
  file,
  pDFFile,
  pdf,
  remnant,
  remnantsChangerUpper,
  storageChangerMain,
  remnantChanger,
  sessionSettings,
  // finance
  balance,
  completedJob,
  dDT,
  dDS,
  workerRecord,
  workerProfile,
  job,
  jobAnother,
  workerList,
  transaction,
  // report
  document,
  documentVolume,
  documentSession,
  report,
  // shipmentFBO
  shipment,
  shipmentDraft,
  driverCar,
  driver,
  // productionOrder
  productionOrder,
  // shipmentFBS
  markingFBS,
  fBSOrder: fbsOrder,
  shipmentFBS,
  fbsSettings,
  // payment
  payment,
  tariffs,
  // analytics
  requirements,
  // marking
  marking,
  // wbDeliveryPredict
  analyzerSettings,
  wbDeliveryPredict,
};
const store = createStore({
  modules,
});
export default store;
