<template>
  <div class="message-list">
    <template v-for="(msg, idx) in messages">
      <Toast
        v-if="msg.message"
        :key="idx"
        :msg="msg"
        :color="getColorByType(msg.type)"
        :icon="getIconByType(msg.type)"
        :message="msg.message"
        :idx="idx"
        @removeMessageById="removeMessageById(idx)"
      />
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Toast from '@/components/Toast';

export default {
  name: 'NotificationHandler',
  components: {
    Toast,
  },
  data: () => ({
    messages: [],
    interval: null,
    updateTimer: true,
    refreshing: false,
    logginOut: false,
  }),
  computed: {
    ...mapState('ajax', {
      storeMessages: (s) => s.messages,
    }),
  },
  watch: {
    'storeMessages.length': function () {
      this.messages = { ...this.storeMessages };

      if (this.messages.length !== 0) this.setDelete();
      else clearInterval(this.interval);
    },
  },
  mounted() {
    this.messages = { ...this.storeMessages };
  },
  methods: {
    setDelete() {
      if (this.updateTimer) {
        this.updateTimer = false;
        this.interval = setInterval(() => {
          this.$store.dispatch('ajax/removeMessageByIdx', 0);
          this.updateTimer = true;
          clearInterval(this.interval);
        }, 3000);
      }
    },
    removeMessageById(idx) {
      if (this.messages.length === 1) {
        this.updateTimer = true;
        clearInterval(this.interval);
      }
      this.$store.dispatch('ajax/removeMessageByIdx', idx);
    },
    getColorByType(type) {
      if (type === 'error') return 'red';
      return 'secondary';
    },
    getIconByType(type) {
      if (type === 'error') return 'mdi-alert-circle';
      return 'mdi-check';
    },
  },
};
</script>

<style lang="sass" scoped>
.message-list
  position: fixed
  z-index: 10001
  bottom: 90px
  right: 20px
  display: flex
  flex-direction: column
  gap: 10px

</style>
