<template>
  <component
    :is="component"
    ref="select"
    :model-value="value"
    height="20"
    hide-details
    :variant="variant"
    density="compact"
    item-title="name"
    item-value="id"
    :menu-props="{'transition': 'none', 'min-width': 600, 'max-width': 600, 'content-class': allowSearch ? 'menuList' : '', 'content-props': {style: $slots['append-item'] ? 'padding-bottom: 50px' : ''}, target: null}"
    :items="filteredItems"
    :label="maxCount === 0 ? label : label + `${value?.length || 0}/${maxCount}`"
    :placeholder="placeholder"
    :disabled="disabled"
    :multiple="multiple"
    :clearable="clearable"
    :no-data-text="'Нет данных'"
    :return-object="false"
    class="select"
    @update:modelValue="debouncedInput"
  >
    <template
      v-if="$slots.prepend"
      #prepend
    >
      <slot name="prepend"></slot>
    </template>

    <template
      v-if="$slots.append"
      #append
    >
      <slot name="append"></slot>
    </template>

    <template
      v-if="$slots.item"
      #item="{item, props}"
    >
      <slot
        name="item"
        :item="item"
        :props="props"
      >
        <v-list-item v-bind="props" />
      </slot>
    </template>

    <template #prepend-item>
      <div
        v-show="allowSearch"
        class="prepend-item"
      >
        <v-text-field
          v-model="searchStr"
          variant="outlined"
          density="compact"
          color="primary"
          placeholder="Поиск"
          hide-details
          clearable
          style="pointer-events: auto"
        ></v-text-field>
      </div>
    </template>

    <template #append-item>
      <div
        v-if="$slots['append-item']"
        class="append-item"
      >
        <slot
          name="append-item"
        >
        </slot>
      </div>
      <div
        v-if="allowCustom"
        class="custom-value-input"
      >
        <v-text-field
          v-model="customValue"
          density="compact"
          label="Добавить"
          variant="outlined"
          color="primary"
          hide-details
        >
          <template #append-inner>
            <v-btn
              color="primary"
              size="x-small"
              @click="addCustomValue"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>
    </template>

    <template
      v-if="$slots['prepend-inner']"
      #prepend-inner
    >
      <slot name="prepend-inner">
      </slot>
    </template>
    <template
      v-if="$slots['append-inner']"
      #append-inner
    >
      <slot name="append-inner"></slot>
    </template>

    <template #selection="{index}">
      {{ index === 0 ? selectedName : '' }}
    </template>
  </component>
</template>
<script>
import _ from 'lodash';

export default {
  name: 'UiUnput',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    maxCount: {
      type: Number,
      // 0 = no restrict
      default: 0,
    },
    variant: {
      type: String,
      default: 'filled',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    allowCustom: {
      type: Boolean,
      default: false,
    },
    allowCustomImmediate: {
      type: Boolean,
      default: true,
    },
    allowSearch: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'addCustom'],
  data() {
    return {
      value: null,
      itemsValue: [],
      customValue: '',

      searchStr: '',
    };
  },
  computed: {
    component() {
      return this.allowCustom ? 'v-combobox' : 'v-select';
    },
    filteredItems() {
      if (!this.itemsValue) return [];
      if (!this.searchStr) return this.itemsValue;
      if (!this.itemsValue[0]?.search) return this.items.filter((item) => !!item?.name?.includes(this.searchStr.toLowerCase()));
      return this.itemsValue.filter((item) => !!item?.search?.filter((el) => JSON.stringify(el).toLowerCase().includes(this.searchStr.toLowerCase())).length);
    },
    selectedName() {
      if (this.allowCustom && this.customValue) {
        return this.customValue;
      }
      if (this.multiple) {
        const filteredItems = this.items.filter((item) => this.value.includes(item.id));
        return filteredItems.map((item) => item.name).join(', ') || '';
      }
      return this.items.find((item) => item.id === this.value)?.name || '';
    },
  },
  watch: {
    modelValue() {
      if (typeof this.$refs?.select?._?.ctx['onUpdate:focused'] === 'function') this.$refs?.select?._?.ctx['onUpdate:focused']();
      this.value = this.modelValue;
    },
    items: {
      handler() {
        this.updateItems();
      },
      deep: true,
    },
  },
  beforeMount() {
    // eslint-disable-next-line no-nested-ternary
    this.value = this.multiple ? this.modelValue ? [this.modelValue] : [] : this.modelValue;
    this.itemsValue = JSON.parse(JSON.stringify(this.items));
  },
  methods: {
    debouncedInput: _.debounce(function (val) {
      if (this.multiple && val.length && this.maxCount && val.length > this.maxCount) {
        val = val.slice(0, this.maxCount);
      }
      if (this.value !== val) {
        this.value = val;
        this.$emit('update:modelValue', this.value);
      }
    }, 200),
    customFilter(itemTitle, queryText, item) {
      if (!item.raw.search) return itemTitle.includes(queryText);
      return !!item.raw.search.filter((el) => el.includes(queryText)).length;
    },
    addCustomValue() {
      if (!this.allowCustomImmediate) {
        this.$emit('addCustom', this.customValue);
      } else {
        this.itemsValue.push({
          id: this.customValue,
          name: this.customValue,
        });
      }
      this.customValue = '';
    },
    updateItems() {
      this.itemsValue = JSON.parse(JSON.stringify(this.items)).filter((item) => !item.hide);
    },
  },
};
</script>

<style lang="sass">
.v-field__input
  position: static

.v-select__selection
  white-space: nowrap

.menuList
  .v-list
    margin-top: 60px
    position: relative
</style>

<style lang="sass" scoped>
.custom-value-input
  display: grid
  grid-template-columns: 1fr
  gap: 10px
  padding-inline: 20px
  padding-block: 10px

.append-item
  position: fixed
  bottom: 0
  padding: 13px
  margin-top: 20px
  background: white
  width: 100%
  z-index: 10000
  border-top: 1px solid #eee
  border-radius: 0 0 5px 5px
  box-shadow: 0px 5px 5px -3px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px 8px 10px 1px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px 3px 14px 2px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12))

.prepend-item
  position: fixed
  top: 0
  background: white
  width: 100%
  z-index: 10000
  padding-inline: 20px
  padding-block: 10px
  border-radius: 5px 5px 0 0
  box-shadow: 0px -5px 5px -3px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)), 0px -8px 10px 1px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)), 0px -3px 14px 2px var(--v-shadow-key-ambient-opacity, rgba(0, 0, 0, 0.12))

.v-select .v-select__selection:first-child
  margin-inline-start: 15px
</style>