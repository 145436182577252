<template>
  <div
    v-if="type === 'string'"
    class="str"
  >
    <UiInput
      v-model="value"
      height="20"
      hide-details
      variant="filled"
      density="compact"
      clearable
      :label="field?.label"
      :disabled="isReadOnly"
      @update:modelValue="update"
    >
      <template #prepend-inner>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </UiInput>
  </div>

  <div
    v-else-if="type === 'text'"
    class="str"
  >
    <v-textarea
      v-model="value"
      hide-details
      variant="filled"
      density="compact"
      clearable
      :label="field?.label"
      :disabled="isReadOnly"
      @update:modelValue="update"
    >
      <template #prepend-inner>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </v-textarea>
  </div>

  <div
    v-else-if="type === 'int' || type === 'number'"
    class="str"
  >
    <v-text-field
      v-model="value"
      height="20"
      hide-details
      variant="filled"
      density="compact"
      type="number"
      clearable
      :label="field?.label"
      :disabled="isReadOnly"
      @update:modelValue="update"
    >
      <template #prepend-inner>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </v-text-field>
  </div>

  <template v-else-if="type === 'selectbox_storage'">
    <StoragePicker
      v-model="value"
      :prop-items="field.values"
      :storages="field.storages"
      :prop-areas="field.areas"
      :field="field"
      density="compact"
      :multiple="false"
      is-edit
      :label="field?.label"
      :disabled="isReadOnly"
      @update:modelValue="update"
    >
      <template #prepend-inner>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </StoragePicker>
  </template>

  <template v-else-if="type === 'selectbox'">
    <UiSelectSearch
      v-model="value"
      height="20"
      hide-details
      variant="filled"
      density="compact"
      :items="items"
      item-title="name"
      item-value="id"
      clearable
      :disabled="isReadOnly"
      :multiple="field.is_collection"
      :allow-custom="field?.allow_custom_values"
      :max-count="field?.max_count || 0"
      :label="field?.label"
      @update:modelValue="update"
    >
      <template #prepend-inner>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </UiSelectSearch>
  </template>

  <template v-else-if="type === 'checkbox'">
    <v-checkbox
      v-model="value"
      type="checkbox"
      hide-details
      :label="field?.label"
      :readonly="field.only_read"
      @update:modelValue="update"
    >
      <template #prepend>
        <Help
          :fields="fields"
          :field-name="fieldName"
          class="ml-5"
        />
      </template>
    </v-checkbox>
  </template>

  <template v-else-if="type === 'timeslot'">
    <TimeslotPicker
      v-model="value"
      :label="field?.label"
      :disabled="field.only_read"
      @update:modelValue="update"
    >
      <template #hint>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </TimeslotPicker>
  </template>

  <template v-else-if="type === 'date'">
    <DatePicker
      v-model="value"
      clearable
      :set-default-value="false"
      :label="field?.label"
      :disabled="field.only_read"
      @update:modelValue="update"
    >
      <template #prepend-inner>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </DatePicker>
  </template>

  <template v-else-if="type === 'datetime'">
    <DatePicker
      v-model="value"
      time
      clearable
      :label="field?.label"
      :disabled="field.only_read"
      @update:modelValue="update"
    >
      <template #prepend-inner>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </DatePicker>
  </template>

  <WorkRecordsTableEdit
    v-else-if="fieldName === 'work_records'"
    v-model="value"
    :fields="field"
    :label="field?.label"
    @update:modelValue="update"
  >
    <template #hint>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </WorkRecordsTableEdit>

  <DefaultTableEdit
    v-else-if="type === 'default_table'"
    v-model="value"
    :raw-data="showDataRaw[fieldName]?.values || []"
    :fields="field"
    :field-name="fieldName"
    :read-only="field.only_read"
    :label="field?.label"
    :loading="loading"
    :readonly-rule="readOnlyRuleDefaultTable"
    :upper-field="fields"
    @update:chapter="onChapterUpdate"
    @update:modelValue="update"
    @update:raw="updateRawDataField(fieldName, $event)"
    @set-watch-query="onSetWatchQuery"
  >
    <template #hint>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </DefaultTableEdit>

  <DefaultTableEdit
    v-else-if="type === 'default_table_chapters'"
    v-model="value"
    :raw-data="showDataRaw[fieldName]?.values || []"
    :fields="field"
    :field-name="fieldName"
    :read-only="field.only_read"
    :label="field?.label"
    :is_chapter="true"
    :loading="loading"
    :readonly-rule="readOnlyRuleDefaultTable"
    @update:chapter="onChapterUpdate"
    @update:modelValue="update"
    @update:raw="updateRawDataField(fieldName, $event)"
    @set-watch-query="onSetWatchQuery"
  >
    <template #hint>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </DefaultTableEdit>

  <MainProductsEdit
    v-else-if="type === 'main_products'"
    v-model="value"
    :raw-data="showDataRaw[fieldName] || []"
    :fields="field"
    :field-name="fieldName"
    :read-only="field.only_read"
    :label="field?.label"
    :upper-field="fields"
    @update:modelValue="update"
  >
    <template #hint>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </MainProductsEdit>

  <Section
    v-else-if="type === 'section' && value"
    v-model="value"
    :fields="field"
    :field-name="fieldName"
    :read-only="field.only_read"
    :show-label="true"
    :label="field?.label"
    @update:modelValue="update"
  >
    <template #hint>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </Section>

  <template v-else-if="type === 'select_storage_letter'">
    <StorageLetterSelect
      v-model="value"
      :exclude="field.active_letters"
      :label="field?.label"
      @update:modelValue="update"
    >
      <template #prepend-inner>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </StorageLetterSelect>
  </template>

  <template v-else-if="type === 'uploaded_file'">
    <UploadedFile
      v-model="value"
      :accept="field.file_extension"
      :disabled="isReadOnly"
      :label="field?.label"
      :multiple="!!field?.is_collection"
      :raw-data="showDataRaw[fieldName]?.value !== undefined ? showDataRaw[fieldName].value : showDataRaw[fieldName]"
      @update:modelValue="update"
      @update:raw="updateRawDataField(fieldName, $event)"
    >
      <template #hint>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </UploadedFile>
  </template>

  <template v-else-if="fieldName === 'sections'">
    <CreateRole
      v-model="value"
      :store-module="'role'"
      :label="field?.label"
      @update:modelValue="update"
    >
      <template #hint>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </CreateRole>
  </template>

  <template v-else-if="fieldName === 'size_id'">
    <BoxSizeSelect
      v-model="value"
      :items="items"
      density="compact"
      :label="field?.label"
      :field="field"
      @update:modelValue="update"
    >
      <template #prepend-inner>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </BoxSizeSelect>
  </template>

  <UiSelectboxProduct
    v-else-if="type === 'selectbox_organization_product'"
    v-model="value"
    :label="field.label"
    class="my-2"
    :items="field.values"
    density="compact"
    :multiple="field.is_collection"
    :max-count="field?.max_count || 0"
    hide-details
    :disabled="isReadOnly"
    @update:modelValue="update"
  >
    <template #prepend-inner>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </UiSelectboxProduct>

  <UiSelectboxOrganization
    v-else-if="type === 'selectbox_organization'"
    v-model="value"
    :items="field.organizations"
    density="compact"
    hide-details
    clearable
    :multiple="field.is_collection"
    :max-count="field?.max_count || 0"
    :disabled="isReadOnly"
    :label="field.label"
    @update:modelValue="update"
  >
    <template #prepend-inner>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </UiSelectboxOrganization>

  <selectboxProductGroup
    v-else-if="type ==='selectbox_product_group'"
    v-model="value"
    :items="field.values"
    density="compact"
    multiple
    hide-details
    clearable
    :disabled="isReadOnly"
    :label="field.label"
    @update:modelValue="update"
  >
    <template #prepend-inner>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </selectboxProductGroup>

  <SelectboxLazy
    v-else-if="type ==='selectbox_lazy'"
    v-model="value"
    :items="field.values"
    density="compact"
    :multiple="field.is_collection"
    hide-details
    clearable
    :disabled="isReadOnly"
    :label="field.label"
    :search-loading="loading"
    @update:modelValue="update"
    @update:search="$emit('setWatchQuerySearch', $event, fieldName)"
  >
    <template #prepend-inner>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </SelectboxLazy>

  <SelectboxCreate
    v-else-if="type === 'selectbox_create'"
    v-model="value"
    :field="field"
    :label="field.label"
    class="my-2"
    :items="field.values"
    density="compact"
    :multiple="field.is_collection"
    :max-count="field?.max_count || 0"
    hide-details
    :disabled="isReadOnly"
    @update:modelValue="update"
  />

  <Gallery
    v-else-if="type ==='gallery'"
    v-model="value"
    :photo-array="showDataRaw[fieldName]?.values || []"
    :label="field.label"
    @update:modelValue="update"
  >
    <template #hint>
      <Help
        :fields="fields"
        :field-name="fieldName"
      />
    </template>
  </Gallery>

  <BarcodeGenerator
    v-else-if="type === 'string_barcode_generate'"
    :key="fieldName"
    v-model="value"
    :label="field.label"
    @update:modelValue="update"
  />

  <div v-else>
    <Label>
      <template #default>{{ field.label }}</template>
      <template #hint>
        <Help
          :fields="fields"
          :field-name="fieldName"
        />
      </template>
    </Label>
    <br>
    {{ value }}
  </div>
</template>
<script setup>
import UiSelectboxOrganization from '@/components/UiKit/Form/SelectboxOrganization/index.vue';
import CreateRole from '@/components/RolePicker/index.vue';
import UiSelectboxProduct from '@/components/UiKit/Form/SelectboxProduct/index.vue';
import Label from '@/components/UiKit/Label/index.vue';
import UiInput from '@/components/UiKit/Form/Input/index.vue';
import StoragePicker from '@/components/UiKit/StoragePicker/index.vue';
import TimeslotPicker from '@/components/UiKit/Form/TimeslotPicker/index.vue';
import BoxSizeSelect from '@/components/UiKit/Form/SelectboxBoxSize/index.vue';
import MainProductsEdit from '@/components/Tables/MainProducts/edit/index.vue';
import WorkRecordsTableEdit from '@/components/Tables/WorkRecords/edit/index.vue';
import SelectboxLazy from '@/components/UiKit/Form/SelectboxLazy/index.vue';
import UiSelectSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import UploadedFile from '@/components/UiKit/UploadedFile/index.vue';
import Gallery from '@/components/UiKit/Gallery/index.vue';
import DatePicker from '@/components/UiKit/Form/DatePicker/index.vue';
import Section from '@/components/Section/edit/index.vue';
import Help from '@/components/UiKit/Help/index.vue';
import StorageLetterSelect from '@/components/UiKit/Form/StorageLetterSelect/index.vue';
import selectboxProductGroup from '@/components/UiKit/Form/SelectboxProductGroup/index.vue';
import DefaultTableEdit from '@/components/Tables/DefaultTable/components/edit/DefaultTableEdit.vue';
import BarcodeGenerator from '@/components/UiKit/BarcodeGenerator/index.vue';
import SelectboxCreate from '@/components/UiKit/Form/SelectboxCreate/index.vue';
</script>

<script>
import logMixin from '@/mixins/log';

export default {
  name: 'EditableField',
  mixins: [logMixin],
  provide() {
    return {
      type: 'change',
    };
  },
  inject: ['readOnlyRuleDefaultTable'],
  props: {
    modelValue: { required: true },
    fields: {
      type: Object,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    showDataRaw: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canBeReadOnly: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'update', 'setWatchQuerySearch', 'setWatchQuery', 'update:raw'],
  data() {
    return {
      value: '',
    };
  },
  computed: {
    field() {
      return this.fields[this.fieldName];
    },
    type() {
      return this.field?.field_type;
    },
    items() {
      return this.field?.values;
    },
    isReadOnly() {
      return this.field?.only_read && this.canBeReadOnly;
    },
  },
  watch: {
    modelValue() {
      if (this.modelValue !== undefined) this.value = JSON.parse(JSON.stringify(this.modelValue));
    },
  },
  beforeMount() {
    if (this.modelValue !== undefined) this.value = JSON.parse(JSON.stringify(this.modelValue));
  },
  methods: {
    update() {
      this.$emit('update:modelValue', this.value);
    },
    updateRawDataField(
      field, $event,
    ) {
      const obj = { ...this.showDataRaw };
      if (!field.is_collection) {
        obj[field] = { value: $event };
      } else {
        obj[field] = { values: $event };
      }
      this.$emit('update:raw', obj);
    },
    onChapterUpdate({ values }) {
      if (typeof values !== 'object') return;
      Object.entries(values).forEach(([field, value]) => {
        this.$emit('setWatchQuery', { value, field });
      });
    },
    onSetWatchQuery({ value, field }) {
      if (typeof value !== 'object' || Array.isArray(value)) this.$emit('setWatchQuery', { value, field });
    },
  },
};
</script>