<template>
  <tr
    style="height: 35px"
  >
    <template v-if="isConsignment">
      <td v-if="fields.organization_product_from_id">
        {{ organizationProductFrom?.name || 'Неизвестный товар' }}
      </td>
      <td class="d-flex align-center">
        <v-btn
          :icon="value.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          variant="text"
          size="x-small"
          @click="value.isOpen = !value.isOpen"
        />
        <p class="line-clamp-2">
          {{ organizationProduct?.name || 'Неизвестный товар' }} ({{ value.session_products.length }})
        </p>

        <v-btn
          v-if="!fields.only_read || canCreateSessionProduct"
          size="extra-small"
          class="text-caption px-2 ml-2"
          color="primary"
          icon="mdi-plus"
          @click="addEmptySessionProduct(value)"
        />
      </td>
      <td v-if="fields.count">
        <p>
          {{ getSessionProductsCount('count') }} шт.
        </p>
      </td>
      <td>
        <p>{{ getSessionProductsCount('count_plan') }} шт.</p>
      </td>
      <td v-if="fields.count_not_distributed">
        <p>{{ undistributedCount }}</p>
      </td>
      <td
        v-for="(field, i) of Object.entries(payloadFields)"
        :key="i"
        class="text-left"
        style="min-width: 200px"
      >
        <PayloadField
          :key="field[0]"
          :product="value"
          :field="field[1]"
          :field-name="field[0]"
          :readonly="(readOnly && fields[field[0]].only_read !== false) || fields[field[0]].only_read"
          :index="j"
          @update:product="''"
        />
      </td>
      <td class="d-flex align-center">
        <template v-if="!readOnly">
          <v-btn
            size="extra-small"
            class="text-caption ml-2 px-2"
            color="error"
            icon="mdi-close"
            @click="del(index)"
          />
        </template>
      </td>
    </template>
    <template v-else>
      <td v-if="fields.organization_product_from_id">
        <UiSelectboxProduct
          v-if="!isConsignment && value.main_organization_product_from"
          v-model="value.main_organization_product_from.id"
          :label="fields['organization_product_from_id']?.label"
          :items="fields['organization_product_from_id']?.values"
          density="compact"
          :multiple="fields['organization_product_from_id'].is_collection"
          :max-count="fields['organization_product_from_id']?.max_count || 0"
          hide-details
          :loading="mainProductsLoading"
          :disabled="(readOnly && fields['organization_product_from_id'].only_read !== false) || fields['organization_product_from_id'].only_read || readonlyRule(value)"
          @update:modelValue="updateInnerValue"
        />
        <template v-else>
          <div class="d-flex align-center">
            <v-btn
              :icon="value.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
              variant="text"
              size="x-small"
              @click="value.isOpen = !value.isOpen"
            />
            <p class="line-clamp-2">
              {{ organizationProductFrom?.name }} ({{ value.session_products.length }})
            </p>
            <v-btn
              v-if="!fields.only_read || !fields.can_create_session_product"
              size="extra-small"
              class="text-caption px-2 ml-2"
              color="primary"
              icon="mdi-plus"
              @click="addEmptySessionProduct(value)"
            />
          </div>
        </template>
      </td>
      <td class="d-flex align-center">
        <UiSelectboxProduct
          v-if="value.main_organization_product && !organizationProductFrom?.is_need_consignment"
          v-model="value.main_organization_product.id"
          :label="fields['organization_product_id']?.label"
          :items="organizationProductValues"
          density="compact"
          :multiple="fields['organization_product_id'].is_collection"
          :max-count="fields['organization_product_id']?.max_count || 0"
          hide-details
          :loading="mainProductsLoading"
          :disabled="
            (readOnly && fields['organization_product_id'].only_read !== false) ||
              fields['organization_product_id'].only_read ||
              readonlyRule(value) ||
              !isOrganizationProductEnabled()
          "
          @update:modelValue="updateInnerValue; onMainProductSelect($event, value)"
        />
      </td>
      <td v-if="fields.count">
        <v-text-field
          v-model="value['count']"
          :label="fields['count']?.label"
          min="0"
          density="compact"
          type="number"
          hide-details
          :loading="mainProductsLoading"
          :disabled="(readOnly && fields['count']?.only_read !== false) || fields['count']?.only_read || readonlyRule(value)"
          @update:modelValue="updateInnerValue"
        >
          <template #append-inner>
            <v-btn
              v-if="isMarking"
              color="primary"
              variant="tonal"
              size="x-small"
              @click.stop="openMarkedProductsModal(organizationProduct)"
            >
              <v-icon>mdi-qrcode-scan</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </td>
      <td v-if="fields.count_plan">
        <v-text-field
          v-model="value['count_plan']"
          :label="fields['count_plan']?.label"
          min="0"
          density="compact"
          type="number"
          hide-details
          :loading="mainProductsLoading"
          :disabled="(readOnly && fields['count_plan']?.only_read !== false) || fields['count_plan']?.only_read || readonlyRule(value)"
          @update:modelValue="updateInnerValue"
        />
      </td>
      <td v-if="fields.count_not_distributed">
        <v-text-field
          :model-value="value.count_not_distributed"
          :label="fields['count_not_distributed']?.label"
          min="0"
          density="compact"
          type="number"
          hide-details
          :loading="mainProductsLoading"
          :disabled="(readOnly && fields['count_not_distributed']?.only_read !== false) || fields['count_not_distributed']?.only_read || readonlyRule(value)"
          @update:modelValue="updateInnerValue"
        />
      </td>
      <td
        v-for="(field, j) of Object.entries(payloadFields)"
        style="min-width: 100px"
      >
        <PayloadField
          :key="j"
          :product="value"
          :field="field[1]"
          :field-name="field[0]"
          :loading="mainProductsLoading"
          :disabled="(readOnly && fields[field[0]].only_read !== false) || fields[field[0]].only_read"
          :index="j"
          @update:product="onPayloadFieldUpdate"
        />
      </td>
      <td>
        <v-btn
          v-if="!readOnly"
          size="extra-small"
          class="text-caption px-2"
          color="error"
          icon="mdi-close"
          @click="del(index)"
        />
      </td>
    </template>
  </tr>
  <template v-if="value.isOpen && !value.isDelete">
    <SessionProductRow
      v-for="(product, j) of value.session_products"
      :key="j"
      :product="product"
      :parent-value="value"
      :payload-fields="payloadFields"
      :read-only="readOnly || readonlyRule(value) || product.is_need_consignment"
      @update:product="onSessionProductUpdate($event, j)"
      @update:field="onFieldUpdate"
      @del-session-product="delSessionProduct(j)"
      @openMarkedModal="openMarkedProductsModal"
    />
  </template>
</template>
<script>
import Section from '@/components/Section/edit/index.vue';
import BoxSizeSelect from '@/components/UiKit/Form/SelectboxBoxSize/index.vue';
import CarSelect from '@/components/UiKit/Form/SelectboxCar/index.vue';
import MainProductsTableEdit from '@/components/Tables/MainProducts/edit/index.vue';
import DriverSelect from '@/components/UiKit/Form/SelectboxDriver/index.vue';
import storagePicker from '@/components/UiKit/StoragePicker/index.vue';
import InputSearch from '@/components/UiKit/Form/SelectboxSearch/index.vue';
import IntegrationsModal from '@/components/Organizations/integrations/modal.vue';
import SessionProductRow
  from '@/components/Tables/MainProducts/edit/components/TableBody/components/TableRow/components/SessionProductRow.vue';
import PayloadField
  from '@/components/Tables/MainProducts/edit/components/TableBody/components/TableRow/components/PayloadField.vue';
import UiSelectboxProduct from '@/components/UiKit/Form/SelectboxProduct/index.vue';
import { payloadMixin } from '@/mixins/payload';
import { getChapterFieldKey } from '@/utils/utils';

export default {
  name: 'TableRow',
  components: {
    MainProductsTableEdit,
    Section,
    BoxSizeSelect,
    CarSelect,
    DriverSelect,
    storagePicker,
    InputSearch,
    IntegrationsModal,
    SessionProductRow,
    PayloadField,
    UiSelectboxProduct,
  },
  mixins: [payloadMixin],
  inject: ['mainProductsLoading', 'storeModule', 'fieldName', 'canCreateSessionProduct', 'fields', 'upperFields', 'pathToField', 'openMarkedModal'],
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    index: {
      type: Number,
      default: 0,
    },
    payloadFields: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    payloadParent: {
      type: String,
      default: null,
    },
    innerIndex: {
      type: Number,
      default: null,
    },
    appendFields: {
      type: Array,
      default: () => [],
    },
    appendItems: {
      type: Array,
      default: () => [],
    },
    readonlyRule: {
      type: Function,
      default: () => false,
    },
    deleteRule: {
      type: Function,
      default: () => false,
    },
    label: {
      type: String,
      default: '',
    },
    emptyElement: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:item', 'delete', 'openMarkedModal'],
  data() {
    return {
      value: {},

      upperItems: [],
    };
  },
  computed: {
    organizationId() {
      const orgKey = getChapterFieldKey({ field: 'organizations', chapterField: 'organization_id' });
      return this.chapterValues[orgKey];
    },
    organization() {
      return this.payload.organizations?.find((org) => String(org.organization_id) === String(this.organizationId));
    },
    organizationProduct() {
      return this.fields?.organization_product_id?.values?.find((el) => el.id === this.value?.main_organization_product?.id);
    },
    organizationProductFrom() {
      return this.fields?.organization_product_from_id?.values?.find((el) => el.id === this.value?.main_organization_product_from?.id);
    },
    showData() {
      return this.$store.getters[`${this.storeModule}/showData`];
    },
    chapterValues() {
      return this.$store.getters['common/chapterValues'];
    },
    paths() {
      return this.fields.child_main_products_paths;
    },
    organizationProductValues() {
      return this.organizationProductFrom?.id
        ? this.getOrganizationProductFromProducts() : this.fields.organization_product_id?.values;
    },
    isMarking() {
      return !!this.organizationProduct?.is_marking;
    },
    isConsignment() {
      return !!this.organizationProduct?.is_need_consignment;
    },
  },
  watch: {
    item() {
      this.value = { ...this.item };
      this.setUpperItems();
    },
    organizationId() {
      this.setUpperItems();
    },
    'value.count_plan': {
      handler() {
        this.setUndistributedCount();
      },
    },
    paths() {
      this.setUpperItems();
    },
  },
  beforeMount() {
    this.value = { ...this.item };
    this.setUpperItems();
  },
  methods: {
    getOrganizationProduct(id) {
      return this.fields.organization_product_id.values.find((el) => el.id === id);
    },
    getOrganizationProductFrom(id) {
      if (!this.fields.organization_product_from_id) return {};
      return this.fields.organization_product_from_id.values.find((el) => el.id === id);
    },
    getOrganizationProductFromProducts() {
      const product = this.organizationProductFrom;
      return product?.is_need_consignment ? [] : product?.organization_product_id_values;
    },
    setUpperItems() {
      let upperItems = [];
      if (!this.paths) return;

      let obj = { ...this.organization };

      this.paths.forEach((pathString) => {
        const path = pathString.split('->');
        const upperItemKey = path[path.length - 1];
        path.forEach((k) => {
          if (!obj) return;
          if (!obj[k]) obj[k] = [];
          if (k === upperItemKey) {
            upperItems = [...upperItems, ...obj[k]];
            return;
          }
          const ff = this?.upperFields?.[k];
          const chapterField = ff?.chapter_fields?.[0];
          const chapterValuesKey = getChapterFieldKey({ field: k, chapterField });
          obj = obj[k].find((el) => `${el[chapterField]}` === this.chapterValues[chapterValuesKey]);
        });
      });
      this.upperItems = upperItems;
    },
    setUndistributedCount() {
      const initialCount = this.value.count_plan;
      let newCount = 0;
      let value = initialCount;
      if (!this.paths) return;


      const field = this.fields.child_main_products_field || 'main_products';
      this.upperItems?.forEach((upperItem) => {
        const product = upperItem?.[field]?.find((pr) => `${pr.main_organization_product?.id}` === `${this.value.main_organization_product?.id}`);
        if (!product) return;
        if (product.session_products) {
          product.session_products.forEach((sp) => {
            newCount += +sp.count_plan > 0 ? +sp.count_plan : +sp.count;
          });
        } else {
          newCount += +product.count_plan > 0 ? +product.count_plan : +product.count;
        }
      });
      value = initialCount - newCount;
      value = value > 0 ? value : 0;
      this.value.count_not_distributed = value;
    },
    // Вызывается только тогда когда есть один session_product
    setSessionProductValues() {
      this.value.session_products[0] = {
        ...this.value.session_products[0],
        ...this.value,
        organization_product_id: this.value.organization_product_id,
        organization_product_from_id: this.value.organization_product_from_id,
        session_products: [],
        count_not_distributed: this.undistributedCount,
      };
      const box = this.value.session_products[0]?.boxes?.[0];
      if (box) {
        box.count = this.value.count;
        box.count_plan = this.value.count_plan;
      } else {
        this.value.session_products[0].boxes = [{ count: this.value.count, count_plan: this.value.count_plan, box: null }];
      }
    },
    updateInnerValue() {
      if (!this?.isConsignment) {
        this.setSessionProductValues();
      }
      this.$nextTick(() => {
        this.$emit('update:item', this.value);
      });
    },
    add() {
      this.items.push(structuredClone(this.emptyElement));
      this.updateValue();
    },
    addEmptySessionProduct(item) {
      item.session_products.push({
        count: 0,
        count_plan: 0,
        organization_product: {
          id: null,
        },
        organization_product_id: null,
        organization_product_from: {
          id: null,
        },
        organization_product_from_id: null,
        organization_products_marked: [],
        is_new: true,
      });
      this.updateInnerValue();
    },
    del(idx) {
      this.$emit('delete', idx);
    },
    delSessionProduct(index) {
      this.value.session_products = this.value.session_products
        .filter((el, i) => i !== index);
      this.updateInnerValue();
    },
    onMainProductSelect(id) {
      this.value.main_organization_product = { id };
      this.value.organization_product_id = id;
      this.value.session_products = [{
        count: 0,
        count_plan: 0,
        organization_product: {
          id,
        },
        organization_product_id: id,
        boxes: [{
          box: null,
          count: 0,
          count_plan: 0,
        }],
      }];
      this.value.price = this?.organizationProduct?.price || 0;
      this.updateInnerValue();
    },
    updateSessionProduct() {
      this.value.session_products = this.value.session_products.map((el) => {
        if (el.organization_product?.id === this.value?.main_organization_product?.id) {
          el = { ...el, ...this.value };
        }
        return el;
      });
    },
    onSessionProductUpdate(product, index) {
      this.value.session_products = this.value.session_products.map((el, i) => {
        if (i === index) {
          el = { ...product };
        }
        return el;
      });
      this.updateInnerValue();
    },
    onFieldUpdate({ key, value }) {
      this.value[key] = value;
      this.updateInnerValue();
    },
    getSessionProductsCount(field) {
      const val = this.value.session_products.reduce((acc, el) => {
        acc += +el[field];
        return acc;
      }, 0);
      this.value[field] = val;
      return val;
    },
    isOrganizationProductEnabled(product) {
      if (!this.fields.organization_product_from_id) return true;
      if (product && product?.organization_product_from?.id) return true;
      if (!product && this.value?.main_organization_product_from?.id) return true;
      return false;
    },
    openMarkedProductsModal(product) {
      this.$emit('openMarkedModal', product);
    },
    onPayloadFieldUpdate(val) {
      this.value = { ...this.value, ...val };
      this.updateInnerValue();
    },
  },
};
</script>