<template>
  <v-card>
    <v-card-title class="title">
      Привзяка складов
    </v-card-title>
    <ul class="warehouseList">
      <li class="warehouseList_item">
        <p class="text-caption">
          Склад маркетплейса
        </p>
        <p class="text-caption">
          Наш склад
        </p>
      </li>
      <li
        v-for="(storage, i) of storages"
        :key="i"
        class="warehouseList_item"
      >
        <div>
          {{ storage.name }}
        </div>
        <div>
          <StoragePicker
            v-model="storage.storage_id"
            :storages="ourStorages"
            :field="fields.storages_fbs?.storage_id"
            :prop-items="fields.values"
            :prop-areas="fields.areas"
            :label="fields.label"
            :multiple="false"
            density="compact"
            @update:modelValue="setStorage($event, i)"
          />
        </div>
      </li>
    </ul>
    <div class="pa-4">
      <v-btn
        block
        color="primary"
        @click="submit"
      >
        Готово
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import StoragePicker from '@/components/UiKit/StoragePicker/index.vue';

export default {
  name: 'WarehouseModal',
  components: { StoragePicker },
  props: {
    organizationApi: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Object,
      default: () => {},
    },
    mpCode: {
      type: String,
      default: 'wb',
    },
    rawData: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      value: {},
    };
  },
  computed: {
    storages: {
      get() {
        return this.value.storages_fbs;
      },
      set(val) {
        this.value.storages_fbs = val;
      },
    },
    ourStorages() {
      return this.fields.storages_fbs?.storage_id.storages || [];
    },
  },
  beforeMount() {
    this.value = JSON.parse(JSON.stringify(this.organizationApi));
  },
  methods: {
    submit() {
      this.$emit('close', { ...this.organizationApi, storages_fbs: this.storages });
    },
    setStorage(value, index) {
      this.storages[index].storage_id = value;
    },
  },
};
</script>

<style lang="sass" scoped>
.warehouseList
  padding: 0 20px 20px
  max-height: 400px
  &_item
    display: grid
    grid-template-columns: 1fr 300px
    align-items: center
    gap: 10px
</style>