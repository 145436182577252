import initialMutations from '@/store/initialMutations';

const state = {
  data: {
    workers: [],
    userSections: [],
  },
  modal: {
    data: {},
    meta: {
      isOpen: false,
      name: '',
    },
    modal: {
      data: {},
      meta: {
        isOpen: false,
        name: '',
      },
    },
  },
};
const getters = {
};
const actions = {

  getJobs({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: '/api/workRecord/not-filed/jobs',
      }, { root: true })
        .then(async (res) => {
          commit('setData', { path: 'workers', value: res.data.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getUserJobs({ commit, dispatch }, workerId) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'get',
        query: `/api/workRecord/worker/${workerId}/not-filed/jobs`,
      }, { root: true })
        .then(async (res) => {
          commit('setData', { path: 'userSections', value: res.data.data });
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeJob({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: '/api/workRecord/change',
        sendData: payload,
      }, { root: true })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changeJobs({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      dispatch('ajax/axiosSend', {
        type: 'post',
        query: '/api/workRecords/change',
        sendData: payload,
      }, { root: true })
        .then(async (res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
const mutations = {
  ...initialMutations,
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
