import initialMutations from '@/store/initialMutations';

import { DefaultEntityStore } from '@/store/defaultEntityStore';
import { removeValuesWrapper } from '@/utils/utils';

const store = new DefaultEntityStore('user');

export default {
  namespaced: true,
  state: {
    ...store.state,
    data: {
      jobsAndJobTitles: [],
      showData: {},
      showDataRaw: {},
      salaryHistory: [],
      paidHistory: [],
      balanceList: [],
      date: null,
      organizationId: null,
    },
    modal: {
      data: {},
      meta: {
        isOpen: false,
        name: '',
      },
      modal: {
        data: {},
        meta: {
          isOpen: false,
          name: '',
        },
      },
    },
    addColumns: [
      {
        field: 'actions',
        hide: true,
      },
    ],
    loading: {
      jobTitles: false,
      salaryHistory: false,
    },
  },
  mutations: {
    ...initialMutations,
    ...store.mutations,
    setJobsAndJobTitles(state, data) {
      state.jobsAndJobTitles = data;
    },
    setShowData(state, payload) {
      state.data.showData = payload;
    },
    setShowDataRaw(state, payload) {
      state.data.showDataRaw = payload;
    },
  },
  actions: {
    ...store.actions,
    // eslint-disable-next-line no-unused-vars
    getJobsAndJobTitles({ commit, dispatch, state }, { workerId, date, organizationId }) {
      commit('setLoading', { path: 'jobTitles', value: true });
      let url = `/api/workRecord/worker/${workerId}/jobs-and-job-titles?`;
      if (date) url += `date=${date}&`;
      if (organizationId) url += `organization_id=${organizationId}&`;

      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: url,
          signal: state.abortController.signal,
        }, { root: true })
          .then((res) => {
            commit('setData', { path: 'jobsAndJobTitles', value: res.data.data });
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'jobTitles', value: false });
          });
      });
    },
    getBalanceList({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: '/api/balance/list',
        }, { root: true })
          .then((res) => {
            commit('setData', { path: 'balanceList', value: res.data.data.items });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getQR({ dispatch }) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: '/api/job-title/qrcode',
        }, { root: true })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getFields() {
      return new Promise((resolve) => resolve({ }));
    },
    // eslint-disable-next-line no-unused-vars
    getShow({ state, commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: `api/user/show/${payload.id}`,
        }, { root: true })
          .then((res) => {
            commit('setShowDataRaw', res.data.data);
            commit('setShowData', removeValuesWrapper(structuredClone(res.data.data)));
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getSalaryHistory(
      { state, dispatch, commit }, {
        workerId, startDate, endDate, organizationId,
      },
    ) {
      commit('setLoading', { path: 'salaryHistory', value: true });
      let url = `api/workUserRecord/worker/${workerId}/salary-history?`;
      if (startDate) url += `startDate=${startDate}&`;
      if (endDate) url += `endDate=${endDate}&`;
      if (organizationId) url += `organization_id=${organizationId}&`;

      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: url,
          signal: state.abortController.signal,
        }, { root: true })
          .then((res) => {
            commit('setData', { path: 'salaryHistory', value: res.data.data });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          })
          .finally(() => {
            commit('setLoading', { path: 'salaryHistory', value: false });
          });
      });
    },
    getPaidHistory({ state, dispatch, commit }, { workerId, date, organizationId }) {
      let url = `api/workUserRecord/worker/${workerId}/paid-history?`;
      if (date) url += `date=${date}&`;
      if (organizationId) url += `organization_id=${organizationId}&`;
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'get',
          query: url,
          signal: state.abortController.signal,
        }, { root: true })
          .then((res) => {
            commit('setData', { path: 'paidHistory', value: res.data.data.dds });
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setPaidRecords({ dispatch }, {
      workerId, balanceId, organizationId, date, payload,
    }) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: `/api/workUserRecord/worker/${workerId}/balance/${balanceId}/set-is-paid?date=${date}&organization_id=${organizationId}`,
          sendData: payload,
        }, { root: true })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    setNotPaidRecords({ dispatch }, { workerId, payload }) {
      return new Promise((resolve, reject) => {
        dispatch('ajax/axiosSend', {
          type: 'post',
          query: `/api/workUserRecord/worker/${workerId}/set-is-not-paid`,
          sendData: payload,
        }, { root: true })
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    ...store.getters,
    getJobsAndJobTitles: (state) => state.data.jobsAndJobTitles,
    showData: (state) => state.data.showData,
    showDataRaw: (state) => state.data.showDataRaw,
    date: (state) => state.data.date,
    organizationId: (state) => state.data.organizationId,
  },
};